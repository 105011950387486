// scss
import "./index.scss";

// components
import FaqSearchBar from "../../components/FaqSearchBar";
import FaqGroup from "../../components/FaqGroup";

export const FaqIndex = () => {
    return (
        <>
            {/* 搜尋欄位 */}
            <FaqSearchBar />
            {/* 常見問題 */}
            <FaqGroup />
        </>
    );
};
