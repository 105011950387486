import {useContext} from "react";
import {StoreContext} from "../pages/StoreContext";

function TemplateIntro({templateItem}) {
    const {useGtmSender} = useContext(StoreContext);
    const {template_category_name, template_title, template_intro, template_link} = templateItem;
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    return (
        <>
            <div className="template-intro clearfix">
                <img
                    src={`../images/template/${template_link}/detail_img.jpg`}
                    alt={template_category_name}
                    className="full"
                />
                <div className="intro">
                    <span>{template_category_name}</span>
                    <h3>{template_title}</h3>
                    <p>{template_intro}</p>
                    <a
                        href={`https://bbin-news.org/event_custom/${template_link}/#/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() =>
                            handleGtmClick({
                                event: "模版頁_模版試玩",
                                model: `${template_link}`,
                                model_name: `${template_title}`,
                                model_category: `${template_category_name}`,
                                breadcrumb: "模版頁",
                            })
                        }>
                        模版试玩
                    </a>
                </div>
            </div>
        </>
    );
}

export default TemplateIntro;
