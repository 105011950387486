import {
    // topic1
    HowToCreateCampaign,
    CampaignProcess,
    ConfirmCampaignSuccess,
    EditOptionsDuringCampaign,
    AdjustBonusesDuringCampaign,
    SetMultipleThresholdsCampaign,
    SetBBCasino,
    // topic2
    SupportHierarchyBlacklist,
    ExplanationHierarchyGroupSettings,
    ExplanationHierarchyDistributionThresholdSettings,
    ExplanationHighestOptionalThresholds,
    // topic3
    CalculateEventPoints,
    SharePointsMultipleEvents,
    AccumulatePointsUsingProfitLoss,
    DailyResetAccumulatedPoints,
    SetDailyResetLimitEventPoints,
    // topic4
    FeaturePreviewOnline,
    FeaturePreviewIntro,
    FeaturePreviewPromo,
    // SetEventPreviewPeriod,
    // PreviewThresholdBonusSettings,
    // GenerateEventPagePromoLink,
    // topic5
    EventTimeBeijingOrEastern,
    LongTermEventNoEndTime,
    ExtendedPrizeClaimSetting,
    StopEventAnyTime,
    ChangeEventTime,
    // topic6
    TipsChangingLogoImages,
    ChangeTemplateDuringEvent,
    DownloadEventPackageMaterials,
    UploadPackageWithoutChangingImages,
    NoImagesAfterUploadingPackage,
    // topic7
    CanUploadBonusTable,
    EventPrizeDecimalSetting,
    LimitPrizeClaims,
    WhatIsWinList,
    UnableUploadVirtualActual,
    SetBudgetTips,
    SetWinningZero,
    DisplayHighestPrize,
    // topic8
    CheckEventDistribution,
    CheckTotalPrizeClaimed,
    // topic9
    MembersViewNewEvent,
    // topic10
    DifferencesBetweenEvent,
    SetOutgoingAudit,
    CanIRestrict,
    EventAwardsReverseHandling,
} from "../../pages/Faq/FaqDetailContent";

const FaqDetailList = [
    // 條列細目
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 1,
        detail_title: "营运红包，我要如何创立活动？",
        detail_link: "how-to-create-campaign",
        detail_element: HowToCreateCampaign,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 2,
        detail_title: "创立活动流程",
        detail_link: "campaign-process",
        detail_element: CampaignProcess,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 3,
        detail_title: "创立好活动后，如何确认活动以建立成功？",
        detail_link: "confirm-campaign-success",
        detail_element: ConfirmCampaignSuccess,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 4,
        detail_title: "活动开始后我能做哪些「编辑」？",
        detail_link: "edit-options-during-campaign",
        detail_element: EditOptionsDuringCampaign,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 5,
        detail_title: "活动进行中，能调整「累计存款」、「累计打码」、「奖金」吗？",
        detail_link: "adjust-bonuses-during-campaign",
        detail_element: AdjustBonusesDuringCampaign,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 6,
        detail_title: "活动能设定「多个门槛」吗？",
        detail_link: "set-multiple-thresholds-campaign",
        detail_element: SetMultipleThresholdsCampaign,
    },
    {
        category_id: 1,
        category_link: "create-campaign",
        detail_id: 7,
        detail_title: "我想指定BB电子的老虎机",
        detail_link: "set-bb-casino",
        detail_element: SetBBCasino,
    },
    // 2========================================
    {
        category_id: 2,
        category_link: "hierarchy-issues",
        detail_id: 1,
        detail_title: "这可以支持层级、黑名单吗？",
        detail_link: "support-hierarchy-blacklist",
        detail_element: SupportHierarchyBlacklist,
    },
    {
        category_id: 2,
        category_link: "hierarchy-issues",
        detail_id: 2,
        detail_title: "派发层级群组设定说明",
        detail_link: "explanation-hierarchy-group-settings",
        detail_element: ExplanationHierarchyGroupSettings,
    },
    {
        category_id: 2,
        category_link: "hierarchy-issues",
        detail_id: 3,
        detail_title: "层级派发门槛设定说明",
        detail_link: "explanation-hierarchy-distribution-threshold-settings",
        detail_element: ExplanationHierarchyDistributionThresholdSettings,
    },
    {
        category_id: 2,
        category_link: "hierarchy-issues",
        detail_id: 4,
        detail_title: "最高门槛与任选门槛说明",
        detail_link: "explanation-highest-optional-thresholds",
        detail_element: ExplanationHighestOptionalThresholds,
    },
    // 3========================================
    {
        category_id: 3,
        category_link: "campaign-points",
        detail_id: 1,
        detail_title: "活动的「点数」如何计算？",
        detail_link: "calculate-event-points",
        detail_element: CalculateEventPoints,
    },

    {
        category_id: 3,
        category_link: "campaign-points",
        detail_id: 2,
        detail_title: "同时举办多个活动，活动的「点数」会共享吗？",
        detail_link: "share-points-multiple-events",
        detail_element: SharePointsMultipleEvents,
    },
    {
        category_id: 3,
        category_link: "campaign-points",
        detail_id: 3,
        detail_title: "活动的点数，能使用「损益」来累计吗？",
        detail_link: "accumulate-points-using-profit-loss",
        detail_element: AccumulatePointsUsingProfitLoss,
    },
    {
        category_id: 3,
        category_link: "campaign-points",
        detail_id: 4,
        detail_title: "累计点数能每日重新计算吗？",
        detail_link: "daily-reset-accumulated-points",
        detail_element: DailyResetAccumulatedPoints,
    },
    {
        category_id: 3,
        category_link: "campaign-points",
        detail_id: 5,
        detail_title: "活动能设定「每日重置点数额度」吗？",
        detail_link: "set-daily-reset-limit-event-points",
        detail_element: SetDailyResetLimitEventPoints,
    },
    // 4========================================
    {
        category_id: 4,
        category_link: "campaign-preview-link",
        detail_id: 1,
        detail_title: "营运红包 预告功能上线 !!",
        detail_link: "feature-preview-online",
        detail_element: FeaturePreviewOnline,
    },
    {
        category_id: 4,
        category_link: "campaign-preview-link",
        detail_id: 2,
        detail_title: "活动网址/推广预告预告 功能说明",
        detail_link: "feature-preview-intro",
        detail_element: FeaturePreviewIntro,
    },
    {
        category_id: 4,
        category_link: "campaign-preview-link",
        detail_id: 3,
        detail_title: "是否可查看活动的图包、门槛、奖金设定？预告页可以外部推广吗？",
        detail_link: "feature-preview-promo",
        detail_element: FeaturePreviewPromo,
    },
    // 5========================================
    {
        category_id: 5,
        category_link: "campaign-duration",
        detail_id: 1,
        detail_title: "活动时间是北京时间还是美东时间？",
        detail_link: "event-time-beijing-or-eastern",
        detail_element: EventTimeBeijingOrEastern,
    },
    {
        category_id: 5,
        category_link: "campaign-duration",
        detail_id: 2,
        detail_title: "我想做长期活动能「不设置结束时间」吗？",
        detail_link: "long-term-event-no-end-time",
        detail_element: LongTermEventNoEndTime,
    },
    {
        category_id: 5,
        category_link: "campaign-duration",
        detail_id: 3,
        detail_title: "「延長领奖」是什么？怎麽设定？",
        detail_link: "extended-prize-claim-setting",
        detail_element: ExtendedPrizeClaimSetting,
    },
    {
        category_id: 5,
        category_link: "campaign-duration",
        detail_id: 4,
        detail_title: "我能随时「停止活动」吗？",
        detail_link: "stop-event-any-time",
        detail_element: StopEventAnyTime,
    },
    {
        category_id: 5,
        category_link: "campaign-duration",
        detail_id: 5,
        detail_title: "我能更改「活动时间」吗？",
        detail_link: "change-event-time",
        detail_element: ChangeEventTime,
    },
    // 6========================================
    {
        category_id: 6,
        category_link: "campaign-images-package",
        detail_id: 1,
        detail_title: "更换LOGO、标题、背景、图包注意事项",
        detail_link: "tips-changing-logo-images",
        detail_element: TipsChangingLogoImages,
    },
    {
        category_id: 6,
        category_link: "campaign-images-package",
        detail_id: 2,
        detail_title: "活动中，我能随时更换活动模版吗？",
        detail_link: "change-template-during-event",
        detail_element: ChangeTemplateDuringEvent,
    },
    {
        category_id: 6,
        category_link: "campaign-images-package",
        detail_id: 3,
        detail_title: "我要去哪里下载「活动图包」、「广宣素材」呢？",
        detail_link: "download-event-package-materials",
        detail_element: DownloadEventPackageMaterials,
    },
    {
        category_id: 6,
        category_link: "campaign-images-package",
        detail_id: 4,
        detail_title: "我不更改图片，我还要再上传图包吗？",
        detail_link: "upload-package-without-changing-images",
        detail_element: UploadPackageWithoutChangingImages,
    },
    {
        category_id: 6,
        category_link: "campaign-images-package",
        detail_id: 5,
        detail_title: "为什么我上传图包后，活动页却「没有出现图片」？",
        detail_link: "no-images-after-uploading-package",
        detail_element: NoImagesAfterUploadingPackage,
    },
    // 7========================================
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 1,
        detail_title: "你们之前有提供「奖金模型表」我能直接使用上传吗？",
        detail_link: "can-upload-bonus-table",
        detail_element: CanUploadBonusTable,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 2,
        detail_title: "活动的派发奖金能设定到「小数点」吗？",
        detail_link: "event-prize-decimal-setting",
        detail_element: EventPrizeDecimalSetting,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 3,
        detail_title: "这能限定领奖次数吗？",
        detail_link: "limit-prize-claims",
        detail_element: LimitPrizeClaims,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 4,
        detail_title: "中奖名单是什麽？",
        detail_link: "what-is-win-list",
        detail_element: WhatIsWinList,
    },
    {
        category_id: 7,
        // category_link: "bonus-prize-settings",
        detail_id: 5,
        detail_title: "『虚拟名单+实际名单』无法上传成功",
        detail_link: "unable-upload-virtual-actual",
        detail_element: UnableUploadVirtualActual,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 6,
        detail_title: "如何设置「预算」？预算注意事项",
        detail_link: "set-budget-tips",
        detail_element: SetBudgetTips,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 7,
        detail_title: "活动能设定抽到「0元（未中奖）」吗？",
        detail_link: "set-winning-zero",
        detail_element: SetWinningZero,
    },
    {
        category_id: 7,
        category_link: "bonus-prize-settings",
        detail_id: 8,
        detail_title: "活动页可以显示最高奖金",
        detail_link: "display-highest-prize",
        detail_element: DisplayHighestPrize,
    },
    // 8========================================
    {
        category_id: 8,
        category_link: "campaign-reports",
        detail_id: 1,
        detail_title: "活动派发相关资讯要去哪里看？",
        detail_link: "check-event-distribution",
        detail_element: CheckEventDistribution,
    },
    {
        category_id: 8,
        category_link: "campaign-reports",
        detail_id: 2,
        detail_title: "派发总奖金、已领奖会员要去哪里看？",
        detail_link: "check-total-prize-claimed",
        detail_element: CheckTotalPrizeClaimed,
    },
    // 9========================================
    {
        category_id: 9,
        category_link: "campaign-promotion",
        detail_id: 1,
        detail_title: "新建好活动之后，会员能从哪里看到我的活动？",
        detail_link: "members-view-new-event",
        detail_element: MembersViewNewEvent,
    },
    // 10========================================
    {
        category_id: 10,
        category_link: "others",
        detail_id: 1,
        detail_title: "「营运红包」跟「营运活动」有什么不一样吗？",
        detail_link: "differences-between-event",
        detail_element: DifferencesBetweenEvent,
    },
    {
        category_id: 10,
        category_link: "others",
        detail_id: 2,
        detail_title: "这能设置出款稽核吗？",
        detail_link: "set-outgoing-audit",
        detail_element: SetOutgoingAudit,
    },
    {
        category_id: 10,
        category_link: "others",
        detail_id: 3,
        detail_title: "我能单独限定APP或寰宇吗？",
        detail_link: "can-i-restrict",
        detail_element: CanIRestrict,
    },
    {
        category_id: 10,
        category_link: "others",
        detail_id: 4,
        detail_title: "活动的派发的奖项可以冲销吗？冲销后包数、总预/每日预算是否会归还？",
        detail_link: "event-awards-reverse-handling",
        detail_element: EventAwardsReverseHandling,
    },
];

export default FaqDetailList;
