import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const E01Tab2 = ({template_link}) => {
    return (
        <div className="content">
            <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
            </p>
            <p>以下针对派发群组进行重点说明</p>
            <p>
                <span className="num2">1</span>
                <span className="num2-content">
                    设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                </span>
            </p>
            <p>
                <span className="num2">2</span>
                <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
            </p>
            <p>
                <span className="num2">3</span>
                <span className="num2-content">
                    派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                    <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
            </p>
            <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
            <p>
                <span className="num2">4</span>
                <span className="num2-content">
                    抽红包：最高门槛优先
                    <br />
                    这部分是显示会员进入活动页的领奖模式
                    <br />
                    <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                    <br />
                    故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                </span>
            </p>
            <p>
                举例1
                <br />
                会员打码点数 5000，您如打码点数门槛由小到大设定如下
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
            </p>
            <p>
                则会员进活动页后系统会让他只能领门槛4的奖金
                <br />
                领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
            </p>
            <p>
                举例2
                <br />
                会员打码点数 5000，您如打码点数门槛设定如下
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
            </p>
            <p>
                则会员进活动页后系统会让他只能领门槛4的奖金
                <br />
                领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
            </p>
            <p>
                <span className="num2">5</span>
                <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
            </p>
            <p>
                <span className="num2">6</span>
                <span className="num2-content">
                    扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                    <br />* 若门槛设定存款及打码，
                    <span>会员需同时符合才能进行领奖</span>
                </span>
            </p>
            <p>
                <span className="num2">7</span>
                <span className="num2-content">
                    假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                    <br />
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
            </p>
            <p>
                ●随机：派发区间值 最小值～最大值
                <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
            </p>
            <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
            </p>
            <p>
                <span className="num2">8</span>
                <span className="num2-content">
                    真实派发奖金设定
                    <br />
                    会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                    <br />
                    故您可于此设定活动预计真实发出的奖金、机率、每日包数
                    <br />
                    奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                    <br />
                    <br />
                    机率：可设定各条奖金的派奖机率
                    <br />
                    <span>●不支持设定0%</span>
                    <br />
                    ●支持设定至小数点后2位
                    <br />
                    ●总和需为100%
                    <br />
                    每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                    <br />
                    ●需注意<span>包数每日都会重置</span>，且
                    <span>活动中无法调整</span>奖项包数
                    <br />
                    ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                    <br />
                    <br />
                    新增奖项 ：可新增至<span>最多10条</span>奖项项次
                </span>
            </p>
        </div>
    );
};

export const E02Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                這是第二個模板
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.jpg`} alt="" />
                </p>
                <p>则会员进活动页后达标门槛皆可以开奖</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E03Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    {" "}
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面固定显示八格，需自行设定
                        <br />
                        ●固定：派发固定值奖金，需设定七组真奖金，真实奖金＝盘面显示假奖金。
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，需设定七组真奖金，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.jpg`} alt="" />
                </p>
                <p>
                    ◆ 活动说明页显示<span>最高奖金</span> 。
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的<span>奖金、机率、每日包数</span>
                        <br />
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        * 您派发出的真奖金需符合您所设定的假奖金
                        <br />
                        例如：设置区间假奖金为288~388、488~688
                        <br />
                        <span className="icon-correct">
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </span>
                        您可以设置：288~300、488~490；
                        <br />
                        <span className="icon-error">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </span>
                        请勿设置：100~150、100~550、389~400
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E04Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />*
                    同个层级或同等及仅能设定在一个派发群组内
                </p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机)
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面固定显示八格，需自行设定
                        <br />
                        ●固定：派发固定值奖金，需设定七组真奖金，真实奖金＝盘面显示假奖金。
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，需设定七组真奖金，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.jpg`} alt="" />
                </p>
                <p>
                    ◆ 活动说明页显示<span>最高奖金</span> 。
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的<span>奖金、机率、每日包数</span>
                        <br />
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        * 您派发出的真奖金需符合您所设定的假奖金
                        <br />
                        例如：设置区间假奖金为288~388、488~688
                        <br />
                        <span className="icon-correct">
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </span>
                        您可以设置：288~300、488~490；
                        <br />
                        <span className="icon-error">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </span>
                        请勿设置：100~150、100~550、389~400
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E05Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />*
                    同个层级或同等及仅能设定在一个派发群组内
                </p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E06Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />*
                    同个层级或同等及仅能设定在一个派发群组内
                </p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    <br />
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E07Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />*
                    同个层级或同等及仅能设定在一个派发群组内
                </p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E08Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />*
                    同个层级或同等及仅能设定在一个派发群组内
                </p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E09Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.jpg`} alt="" />
                </p>
                <p>则会员进活动页后达标门槛皆可以开奖</p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E10Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.jpg`} alt="" />
                </p>
                <p>则会员进活动页后达标门槛皆可以开奖</p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        假奖金 (固定/随机) ：显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                    </span>
                </p>
                <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若最小值＝0， <span>活动说明页只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E11Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，<b>活动页盘面预设最少为3格、最多8格，</b>
                        <br />
                        如需添加請按『新增奖项』；若减少格数请按『删除』
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>请注意：如初始您设定为7格，存档后您将无法新增或减少该档活动格数</p>
                <p>
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>

                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                </p>
                <p>
                    显示位置： <span>最低</span>假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro09_2.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合且最近的栏位
                    <br />
                    例如：奖金 1~10 (显示随机图示) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    当盘面转动到停止时，如
                    <span style={{textDecoration: "underline", color: "#fff"}}>1~10 (显示随机图示)</span>
                    离转轮指针近将派发『随机』
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro10.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro11.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro12.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro13.png`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro14.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro15.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>

                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E12Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.jpg`} alt="" />
                </p>
                <p>则会员进活动页后达标门槛皆可以开奖</p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，<b>活动页盘面预设最少为3格、最多8格，</b>
                        <br />
                        如需添加請按『新增奖项』；若减少格数请按『删除』
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                </p>
                <p>请注意：如初始您设定为7格，存档后您将无法新增或减少该档活动格数</p>
                <p>
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>

                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机图示显示逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                </p>
                <p>
                    显示位置： <span>最低</span>假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro08_2.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合且最近的栏位
                    <br />
                    例如：奖金 1~10 (显示随机图示) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    当盘面转动到停止时，如
                    <span style={{textDecoration: "underline", color: "#fff"}}>1~10 (显示随机)</span>
                    离转轮指针近将派发『随机』
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro10.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro11.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro12.png`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro13.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro14.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E13Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.jpg`} alt="" />
                </p>
                <p>则会员进活动页后达标门槛皆可以开奖</p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，<b>活动页盘面预设最少为3格、最多8格，</b>
                        <br />
                        如需添加請按『新增奖项』；若减少格数请按『删除』
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                </p>
                <p>请注意：如初始您设定为7格，存档后您将无法新增或减少该档活动格数</p>
                <p>
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>

                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机图示显示逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                </p>
                <p>
                    显示位置： <span>最低</span>假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro08_2.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合且最近的栏位
                    <br />
                    例如：奖金 1~10 (显示随机图示) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    当盘面转动到停止时，如
                    <span style={{textDecoration: "underline", color: "#fff"}}>1~10 (显示随机)</span>
                    离转轮指针近将派发『随机』
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro10.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro11.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro12.png`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro13.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro14.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E14Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                    则会员进活动页后系统会让他只能领门槛4的奖金
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面预设固定5格
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                </p>

                <p>
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>

                <p>
                    『<span>游戏</span>』操作讲解
                    <br />
                    会员选择对应门槛后，进入到游戏画面并需选择 A、B、C、D、E 五个奖金路线
                    <br />
                    系统会从设定的机率随机指向一列奖金进行派发
                </p>
                <p>
                    <ul>
                        <li style={{float: "left", width: "33.3%"}}>
                            ①选择门槛
                            <img src={`../images/template/${template_link}/tab2_intro09.jpg`} alt="" />
                        </li>
                        <li style={{float: "left", width: "33.3%"}}>
                            ②选择五条线路
                            <img src={`../images/template/${template_link}/tab2_intro10.jpg`} alt="" />
                        </li>
                        <li style={{float: "left", width: "33.3%"}}>
                            ③依机率指向奖金派发
                            <img src={`../images/template/${template_link}/tab2_intro11.jpg`} alt="" />
                        </li>
                    </ul>
                </p>
                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机图示显示逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                    <br />
                    显示位置：<span>最低</span>假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro12.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle", padding: "0 0 0 10px"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合且最近的栏位
                    <br />
                    例如：奖金 1~10 (显示随机) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    系统将会随机连线至派发10元的栏位里
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro13.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro14.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro15.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro16.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro17.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro18.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E15Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面预设固定5格
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>

                <p>
                    ●固定：派发固定值奖金，<span>假奖金设定需等于真实奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>

                <p>
                    『<span>游戏</span>』操作讲解
                    <br />
                    会员选择对应门槛后，进入到游戏画面并需选择 A、B、C、D、E 五个奖金路线
                    <br />
                    系统会从设定的机率随机指向一列奖金进行派发
                </p>
                <p>
                    <ul>
                        <li style={{float: "left", width: "33.3%"}}>
                            ①选择门槛
                            <img src={`../images/template/${template_link}/tab2_intro10.jpg`} alt="" />
                        </li>
                        <li style={{float: "left", width: "33.3%"}}>
                            ②选择五条线路
                            <img src={`../images/template/${template_link}/tab2_intro11.jpg`} alt="" />
                        </li>
                        <li style={{float: "left", width: "33.3%"}}>
                            ③依机率指向奖金派发
                            <img src={`../images/template/${template_link}/tab2_intro12.jpg`} alt="" />
                        </li>
                    </ul>
                </p>
                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机图示显示逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                    <br />
                    显示位置：最低假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro13.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle", padding: "0 0 0 10px"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合且最近的栏位
                    <br />
                    例如：奖金 1~10 (显示随机) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    系统将会随机连线至派发10元的栏位里
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro14.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro15.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro16.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro17.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro18.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro19.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};

export const E16Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                        <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，
                        <span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)：</span> 显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为固定值
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且
                        <span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
// 05/14新增
export const E17Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                        <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，
                        <span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)：</span> 显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且
                        <span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
export const E18Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                        <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，
                        <span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)：</span> 显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且
                        <span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
export const E19Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                        <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>

                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                    则会员进活动页后系统会让他只能领门槛4的奖金
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金
                    </span>
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro06.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且
                        <span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
export const E20Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                    <br />
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：任选门槛
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                        <br />
                        <Link
                            to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                            target={"_blank"}
                            className="link">
                            <span>领奖门槛说明</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </Link>
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员累计存款达3,500,000、累计打码达5,000,000
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                    则会员进活动页后达标门槛皆可以开奖
                </p>

                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面预设
                        <span style={{color: "#E8211E", fontWeight: "bold"}}>固定4格</span>
                    </span>
                </p>
                <p>
                    ● 固定：派发固定值奖金，假奖金设定需等于真实奖金
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p style={{marginBottom: "0"}}>
                    <span>
                        『真奖金』设定规则 _ <span style={{color: "#E8211E", fontWeight: "bold"}}>固定</span>
                    </span>
                </p>
                <ul className="clearfix" style={{paddingBottom: "40px"}}>
                    <li style={{float: "left", width: "50%"}}>
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon01.png) no-repeat left center`,
                            }}></span>
                        假奖金必须相同 真奖金数值。
                        <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon02.png) no-repeat left center`,
                            }}></span>
                        不符合假奖金数值
                        <img src={`../images/template/${template_link}/tab2_intro08.jpg`} alt="" />
                    </li>
                    <li style={{float: "left", width: "50%"}}>
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon01.png) no-repeat left center`,
                            }}></span>
                        只设定一组奖金 ( 需符合假奖金数值 )
                        <img src={`../images/template/${template_link}/tab2_intro09.jpg`} alt="" />
                    </li>
                </ul>

                <p>●随机：派发区间值 最小值～最大值，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro10.png`} alt="" />
                </p>
                <p style={{marginBottom: "0"}}>
                    <span>
                        『真奖金』设定规则 _ <span style={{color: "#E8211E", fontWeight: "bold"}}>随机</span>
                    </span>
                </p>
                <ul className="clearfix" style={{paddingBottom: "40px"}}>
                    <li style={{float: "left", width: "50%"}}>
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon01.png) no-repeat left center`,
                            }}></span>
                        假奖金必须包含 真奖金區間数值。
                        <img src={`../images/template/${template_link}/tab2_intro11.jpg`} alt="" />
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon02.png) no-repeat left center`,
                            }}></span>
                        不符合假奖金数值
                        <img src={`../images/template/${template_link}/tab2_intro12.jpg`} alt="" />
                    </li>
                    <li style={{float: "left", width: "50%"}}>
                        <span
                            style={{
                                padding: "0 0 0 24px",
                                background: `url(../images/template/${template_link}/icon01.png) no-repeat left center`,
                            }}></span>
                        只设定一组奖金 ( 需符合假奖金数值 )
                        <img src={`../images/template/${template_link}/tab2_intro13.jpg`} alt="" />
                    </li>
                </ul>

                <p>
                    『<span>随机</span>』图示讲解
                    <br />
                    随机图示显示逻辑：因奖金派发预算已达上限时，会员有可能被派发未中奖奖项，若盘面未设置『未中奖』栏位，系统找不到盘面且避免会员客诉，故设立随机机制
                    <br />
                    显示位置：<span>最低</span>假奖金将显示『随机』图示
                    <img
                        src={`../images/template/${template_link}/tab2_intro14.png`}
                        alt=""
                        style={{display: "inline-block", verticalAlign: "middle", padding: "0 0 0 10px"}}
                    />
                    （最低奖金不包含设定＝0）
                </p>
                <p>※请注意：随机将派发 0 ~ 最大值真奖金数值派发</p>
                <p>
                    『固定』与『随机』的显示逻辑 (以视同未中奖举例说明)
                    <br />
                    <span>最低假奖金皆显示设置『随机』，其奖金数值将调整成：0 ~ 最大值</span>
                </p>
                <p>
                    假设 1：<span>固定</span> 假奖金 <span>8</span>、88、188、288；真奖金 8、88
                    <br />
                    <span className="num2">1</span>
                    <span>盘面</span>：最低假奖金 <span>8</span> 将被『随机』取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 288</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『8 』、『88』
                </p>
                <p>
                    假设 2： <span>随机</span> 假奖金 <span>1688 ~ 2888</span>、6888 ~ 8888、12888 ~ 16888；真奖金 1688
                    ~ 2888、6888 ~ 8888
                    <br />
                    <span className="num2">1</span>
                    盘面：最低假奖金 <span>1688 ~ 2888</span> 将被『随机』图示取代，该数值将变成：
                    <span style={{textDecoration: "underline"}}>0 ~ 16888</span>
                    <br />
                    <span className="num2">2</span>
                    真奖金派发将为『0 』、『1688 ~ 2888 』、『6888 ~ 8888』
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}> Q：会员会不会一直转到随机？</span>
                    <br />
                    A：是有可能的，因盘面转动时，系统会抓取符合的栏位
                    <br />
                    例如：奖金 1~10 (显示随机) 、5~10、8~10，系统派发10元奖金的话
                    <br />
                    系统将会随机连线至派发10元的栏位里
                </p>
                <p>
                    此外，派发随机也与您设置真奖金有关
                    <br />
                    如您在『<span>真奖金</span>』部份，设定 1~10 (显示随机) 、5~10、8~10，机率分别设置95%、3%与2%
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro15.png`} alt="" />
                </p>
                <p>
                    因您的 1~10 奖项设定至95%，故转至 <span style={{textDecoration: "underline"}}>随机栏位</span>
                    的几率较高
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>Q：为什麽我设置的第一组(最低)奖金没有显示？</span>
                    <br />
                    A：因随机逻辑的设置是抓取您<span>最低奖金</span>，故显示『随机』图示 <br />
                    <img src={`../images/template/${template_link}/tab2_intro16.png`} alt="" />
                </p>
                <p>
                    <span style={{fontWeight: "bold", color: "#fff"}}>
                        Q：我设置 0 的话是会出现随机吗？还是画面会出现 0？还是未中奖？
                    </span>
                    <br />
                    A：因您假獎金設置為 0，故畫面显示『未中奖』，就不会在出现『随机』的字样哦 <br />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro17.jpg`} alt="" />
                </p>
                <p>
                    说明页显示
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金， <span>显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro18.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页 <span>只会显示最高奖金</span>
                    <img src={`../images/template/${template_link}/tab2_intro19.png`} alt="" />
                    ◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金
                    <br />
                    <img src={`../images/template/${template_link}/tab2_intro20.png`} alt="" />
                </p>
                <p>
                    请注意： <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>
                    派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>
                    假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>
                    区间值，最小值=0
                    <br />
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span>』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为<span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />
                        <span>●不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
// 07/09新增
export const E21Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                        <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，
                        <span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)：</span> 显示于活动页的最高奖金和说明页中门槛奖金
                        <br />
                        ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
                </p>
                <p>
                    ●随机：派发区间值 最小值～最大值
                    <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>
                <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
                </p>
                <p>
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>
                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的奖金、机率、每日包数
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />●<span>不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且
                        <span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
export const E22Tab2 = ({template_link}) => {
    return (
        <>
            <div className="content">
                <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
                </p>
                <p>以下针对派发群组进行重点说明</p>
                <p>
                    <span className="num2">1</span>
                    <span className="num2-content">
                        设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                    </span>
                </p>
                <p>
                    <span className="num2">2</span>
                    <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
                </p>
                <p>
                    <span className="num2">3</span>
                    <span className="num2-content">
                        派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」</span>或
                        <span>「各等级」</span>的派发群组
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
                </p>
                <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
                <p>
                    <span className="num2">4</span>
                    <span className="num2-content">
                        抽红包：最高门槛优先
                        <br />
                        这部分是显示会员进入活动页的领奖模式
                        <br />
                        <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                        <br />
                        故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                    </span>
                </p>
                <p>
                    举例1
                    <br />
                    会员打码点数 5000，您如打码点数门槛由小到大设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
                </p>
                <p>
                    举例2
                    <br />
                    会员打码点数 5000，您如打码点数门槛设定如下
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
                </p>
                <p>
                    则会员进活动页后系统会让他只能领门槛4的奖金
                    <br />
                    领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
                </p>
                <p>
                    <span className="num2">5</span>
                    <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
                </p>
                <p>
                    <span className="num2">6</span>
                    <span className="num2-content">
                        扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                        <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                    </span>
                </p>
                <p>
                    <span className="num2">7</span>
                    <span className="num2-content">
                        <span>假奖金 (固定/随机)</span>
                        ：显示于活动页的最高奖金和说明页中门槛奖金，活动页盘面固定显示八格，需自行设定
                        <br />
                        ●固定：派发固定值奖金，需设定七组真奖金，真实奖金＝盘面显示假奖金。
                    </span>
                </p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
                </p>
                <p>●随机：派发区间值 最小值～最大值，需设定七组真奖金，真实奖金区间＝盘面显示假奖金区间。</p>
                <p>
                    <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
                </p>

                <p>
                    <span>补充说明：</span>因派发已达上限和未中奖的关系第八格奖金，一率显示ICON{" "}
                    <img
                        src={`../images/template/${template_link}/tab2_intro09.png`}
                        alt=""
                        style={{display: "inline", verticalAlign: "middle"}}
                    />
                    。<br />
                    <span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    请注意：<span>说明页这三种情况下， 一律显示最高</span>
                    <br />
                    <span className="num2">1</span>派发已达上限 : 视为未中奖
                    <br />
                    <span className="num2">2</span>假奖金为<span>固定值</span>
                    <br />
                    <span className="num2">3</span>区间值，最小值=0
                </p>
                <p>
                    举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                    <br />
                    区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                    <br />
                    因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
                </p>
                <p>
                    举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                    <br />
                    区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
                </p>

                <p>
                    <span className="num2">8</span>
                    <span className="num2-content">
                        真实派发奖金设定
                        <br />
                        会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                        <br />
                        故您可于此设定活动预计真实发出的<span>奖金、机率、每日包数</span>
                        <br />
                        奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                        <br />
                        <br />
                        例如：设置区间假奖金为288~388、488~688
                        <br />
                        <span className="icon-correct">
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </span>
                        您可以设置：288~300、488~490；
                        <br />
                        <span className="icon-error">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </span>
                        请勿设置：100~150、100~550、389~400
                        <br />
                        <br />
                        机率：可设定各条奖金的派奖机率
                        <br />●<span>不支持设定0%</span>
                        <br />
                        ●支持设定至小数点后2位
                        <br />
                        ●总和需为100%
                        <br />
                        <br />
                        每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                        <br />
                        ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                        <br />
                        ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                        <br />
                        新增奖项 ：可新增至<span>最多10条</span>奖项项次
                    </span>
                </p>
            </div>
        </>
    );
};
// 07/29新增
export const E23Tab2 = ({template_link}) => {
    return (
        <>
        <div className="content">
            <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
            </p>
            <p>以下针对派发群组进行重点说明</p>
            <p>
                <span className="num2">1</span>
                <span className="num2-content">
                    设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                </span>
            </p>
            <p>
                <span className="num2">2</span>
                <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
            </p>
            <p>
                <span className="num2">3</span>
                <span className="num2-content">
                    派发层级/等级：可依具活动条件设定的派发对象的选项，设定
                    <span>「各层级」</span>或<span>「各等级」</span>的派发群组
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
            </p>
            <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
            <p>
                <span className="num2">4</span>
                <span className="num2-content">
                    抽红包：任选门槛
                    <br />
                    这部分是显示会员进入活动页的领奖模式
                    <br />
                    <span>任选门槛=系统提示最高门槛可领，但不限制会员领取任选门槛</span>
                    <br />
                    <Link
                        to={"/faq/hierarchy-issues/explanation-highest-optional-thresholds"}
                        target={"_blank"}
                        className="link">
                        <span>领奖门槛说明</span>
                        <span className="arrow">
                            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                        </span>
                    </Link>
                </span>
            </p>
            <p>
                举例1
                <br />
                会员累计存款达3,500,000、累计打码达5,000,000
                <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
                则会员进活动页后达标门槛皆可以开奖
            </p>
            
            <p>
                <span className="num2">5</span>
                <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
            </p>
            <p>
                <span className="num2">6</span>
                <span className="num2-content">
                    扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                    <br />* 若门槛设定存款及打码，
                    <span>会员需同时符合才能进行领奖</span>
                </span>
            </p>
            <p>
                <span className="num2">7</span>
                <span className="num2-content">
                    <span>假奖金 (固定/随机)：</span> 显示于活动页的最高奖金和说明页中门槛奖金
                    <br />
                    ●固定：派发固定值奖金，假奖金设定需等于真实奖金，<span>显示最高奖金</span>
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro07.jpg`} alt="" />
            </p>
            <p>
                ●随机：派发区间值 最小值～最大值
                <br />◆ 若假奖金最小值＝0， 活动说明页<span>只会显示最高奖金</span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
            </p>
            <p>◆ 若最小值≠0 ，活动说明页会显示您设定的区间奖金</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro09.png`} alt="" />
            </p>
            <p>
                请注意：<span>说明页这三种情况下， 一律显示最高</span>
                <br />
                <span className="num2">1</span>派发已达上限 : 视为未中奖
                <br />
                <span className="num2">2</span>假奖金为<span>固定值</span>
                <br />
                <span className="num2">3</span>区间值，最小值=0
            </p>
            <p>
                举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                <br />
                区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                <br />
                因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
            </p>
            <p>
                举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                <br />
                区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
            </p>
            <p>
                <span className="num2">8</span>
                <span className="num2-content">
                    真实派发奖金设定
                    <br />
                    会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                    <br />
                    故您可于此设定活动预计真实发出的奖金、机率、每日包数
                    <br />
                    奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                    <br />
                    <br />
                    机率：可设定各条奖金的派奖机率
                    <br />●<span>不支持设定0%</span>
                    <br />
                    ●支持设定至小数点后2位
                    <br />
                    ●总和需为100%
                    <br />
                    <br />
                    每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                    <br />
                    ●需注意<span>包数每日都会重置</span>，且
                    <span>活动中无法调整</span>奖项包数
                    <br />
                    ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                    <br />
                    <br />
                    新增奖项 ：可新增至<span>最多10条</span>奖项项次
                </span>
            </p>
        </div>
    </>
    );
};
export const E24Tab2 = ({template_link}) => {
    return (
        <>
        <div className="content">
            <p>活动页的领奖门槛（底下红框处）主要连动后台的「派发群组」设定</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro01.png`} alt="" />
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro02.jpg`} alt="" />
            </p>
            <p>以下针对派发群组进行重点说明</p>
            <p>
                <span className="num2">1</span>
                <span className="num2-content">
                    设定派发群组：可新增<span>最多10组</span>的派发的门槛条件
                </span>
            </p>
            <p>
                <span className="num2">2</span>
                <span className="num2-content">派发群组名称：非必填，填写后会显示于活动说明页的门槛标题中</span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro03.jpg`} alt="" />
            </p>
            <p>
                <span className="num2">3</span>
                <span className="num2-content">
                    派发层级/等级：可依具活动条件设定的派发对象的选项，设定<span>「各层级」或「各等级」</span>的派发群组
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro04.png`} alt="" />
            </p>
            <p>* 同个层级或同等及仅能设定在一个派发群组内</p>
            <p>
                <span className="num2">4</span>
                <span className="num2-content">
                    抽红包：最高门槛优先
                    <br />
                    这部分是显示会员进入活动页的领奖模式
                    <br />
                    <span>最高门槛优先=会员会优先领取您设定的最后一个门槛内容</span>
                    <br />
                    故建议这类活动门槛要「由小到大」设定，较不会让会员感觉困惑
                </span>
            </p>
            <p>
                举例1
                <br />
                会员打码点数 5000，您如打码点数门槛由小到大设定如下
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro05.png`} alt="" />
            </p>
            <p>
                则会员进活动页后系统会让他只能领门槛4的奖金
                <br />
                领完后打码点数剩下 5,000-3,000=2,000 ，才可以再领门槛3的奖金
            </p>
            <p>
                举例2
                <br />
                会员打码点数 5000，您如打码点数门槛设定如下
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro06.png`} alt="" />
            </p>
            <p>
                则会员进活动页后系统会让他只能领门槛4的奖金
                <br />
                领完后打码点数剩下 5,000-500=4,500 ， <span>会员再次领奖仍只能领门槛4的奖金</span>
            </p>
            <p>
                <span className="num2">5</span>
                <span className="num2-content">派发门槛：可点+来新增最多10组门槛，各别设定指定条件及派发奖金</span>
            </p>
            <p>
                <span className="num2">6</span>
                <span className="num2-content">
                    扣除条件：设定活动门槛<span>存款</span>及<span>打码</span>条件
                    <br />* 若门槛设定存款及打码，<span>会员需同时符合才能进行领奖</span>
                </span>
            </p>
            <p>
                <span className="num2">7</span>
                <span className="num2-content">
                    <span>假奖金 (固定/随机)</span>
                    ：显示于活动页的最高奖金和说明页中门槛奖金，需自行设定
                    <br />
                    ●固定：派发固定值奖金，需设定六组真奖金，真实奖金＝盘面显示假奖金。
                </span>
            </p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro07.png`} alt="" />
            </p>
            <p>●随机：派发区间值 最小值～最大值，需设定六组真奖金，真实奖金区间＝盘面显示假奖金区间。</p>
            <p>
                <img src={`../images/template/${template_link}/tab2_intro08.png`} alt="" />
            </p>

            <p>
                <span>说明页这三种情况下， 一律显示最高</span>
                <br />
                请注意：<span>说明页这三种情况下， 一律显示最高</span>
                <br />
                <span className="num2">1</span>派发已达上限 : 视为未中奖
                <br />
                <span className="num2">2</span>假奖金为<span>固定值</span>
                <br />
                <span className="num2">3</span>区间值，最小值=0
            </p>
            <p>
                举例说明 1：您的派发已达上限选择『<span>视为未中奖</span> 』
                <br />
                区间奖金为 18~188，活动说明页将显示 为 <span>最高188</span>
                <br />
                因为：系统认定 未中奖=0 , 所以输入18~188 将会显示最高奖金
            </p>
            <p>
                举例说明 2：派发已达上限选择『<span>无法抽红包</span>』
                <br />
                区间奖金为 18~188，活动说明页显示 为 <span>18~188</span>
            </p>

            <p>
                <span className="num2">8</span>
                <span className="num2-content">
                    真实派发奖金设定
                    <br />
                    会员领奖时，系统会从设定的机率随机指向一列奖金进行派发
                    <br />
                    故您可于此设定活动预计真实发出的奖金、机率、每日包数
                    <br />
                    奖项金额：真实发出奖金（配合假奖金设定有固定或随机两种）
                    <br />
                    <br />
                    机率：可设定各条奖金的派奖机率
                    <br />●<span>不支持设定0%</span>
                    <br />
                    ●支持设定至小数点后2位
                    <br />
                    ●总和需为100%
                    <br />
                    <br />
                    每日包数限制：设定各条奖金<span>每日</span>最多可派发包数
                    <br />
                    ●需注意<span>包数每日都会重置</span>，且<span>活动中无法调整</span>奖项包数
                    <br />
                    ●每个派发门槛的「项次1」固定奖项包数为「不限制」
                    <br />
                    新增奖项 ：可新增至<span>最多10条</span>奖项项次
                </span>
            </p>
        </div>
    </>
    );
};
