import {Outlet, useLocation} from "react-router-dom";
import {useState} from "react";
import {StoreContext} from "../pages/StoreContext";

// components: 這裡的元件，每一頁都會出現
import PageHeader from "../components/PageHeader";
import PageFooter from "../components/PageFooter";
import GoToTop from "../components/GoToTop"; //返回頂端
import Feedback from "../components/Feedback"; //回饋表單
import LoadPageToTop from "../components/LoadPageToTop"; //載入頁面，都先置頂，如果不需要，拿掉即可

// 比對路徑搭配class name使用
import FaqCategoryList from "../assets/data/FaqCategoryList";
import useGtmSender from "../useGtmSender";

// 每一頁都需要用到的框架
export const PageContainer = () => {
    // 根據路徑，替最外層的div，添加客制class名稱
    const {pathname} = useLocation(); //抓網址使用
    const pathArray = pathname.split("/");
    const firstSegment = pathArray[1] || ""; //如果抓不到這個值，代表他在首頁，所以給我空值
    const secondSegment = pathArray[2]; //幫我判斷是否在詳細頁

    // const checkSecondPath = "";

    let addClassName = "page"; //預設一定有page這個class name

    switch (firstSegment) {
        //首頁情況
        case "":
            addClassName = "page page-first";
            break;
        // 模板頁
        case "template":
            addClassName = "page page-template";
            break;
        // faq
        case "faq":
            addClassName = "page page-faq";
            break;
        default:
            // 默认值
            addClassName = "page";
    }

    if (secondSegment) {
        // 代表是詳細頁，不管是誰的詳細頁(faq or template)
        if (pathArray[1] === "template") {
            // 代表是 template 詳細頁
            addClassName = "page page-template-detail";
        } else if (pathArray[1] === "faq") {
            // 代表是 faq 詳細頁
            addClassName = "page page-faq page-faq-list";
        }
    }

    // 側邊欄(因為組件有在首頁出現，所以要放在全域變數裡面)
    const category = FaqCategoryList.find((item) => pathname.includes(`/${item.category_link}`));
    const [selectedCategoryId, setSelectedCategoryId] = useState(category ? category.category_id : null);
    const handleToggle = (category_id) => {
        setSelectedCategoryId(category_id); // 將展開的主項目設置為點擊的 category_id
    };

    return (
        <>
            <StoreContext.Provider
                value={{
                    // 側邊欄
                    selectedCategoryId,
                    setSelectedCategoryId,
                    handleToggle,
                    // gtm資料
                    useGtmSender,
                }}>
                <LoadPageToTop />
                <Feedback />
                <div className={addClassName}>
                    <PageHeader />
                    <main>
                        {/* 內頁 */}
                        <Outlet />
                        {/* 回頂端 */}
                        <GoToTop />
                    </main>
                    <PageFooter />
                </div>
            </StoreContext.Provider>
        </>
    );
};
