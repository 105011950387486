import {
    // tab2組件內容
    E01Tab2,
    E02Tab2,
    E03Tab2,
    E04Tab2,
    E05Tab2,
    E06Tab2,
    E07Tab2,
    E08Tab2,
    E09Tab2,
    E10Tab2,
    E11Tab2,
    E12Tab2,
    E13Tab2,
    E14Tab2,
    E15Tab2,
    E16Tab2,
    // 05/14
    E17Tab2,
    E18Tab2,
    E19Tab2,
    // 05/30
    E20Tab2,
    // 07/09
    E21Tab2,
    E22Tab2,
    // 0729
    E23Tab2,
    E24Tab2,
} from "../../pages/Template/TemplateDetailContent";

const TemplateItemsData = [
    // template_category:目前暫定有5個分類 "节庆模版 festival","趣味模版 joy ","体育模版 sports ","视讯模版 live", "捕鱼模版 fishing"
    // template_tag: "hot"、"new" 兩種
    {
        template_id: 1,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "金喜紅包雨",
        template_intro: "金喜紅包雨 免费彩金抢不停",
        template_link: "E01",
        template_tab2: E01Tab2, //E01
        template_tag: "",
        template_download_event_title: "红包雨 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/红包雨 - 活动图包.zip",
        template_download_template_title: "红包雨 - 模版图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/红包雨 - 模版图包.zip",
        template_download_promotion_title: "红包雨 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/红包雨 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 2,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "迎接双12",
        template_intro: "迎接双12 百万狂赠到底",
        template_link: "E02",
        template_tab2: E02Tab2,
        template_tag: "",
        template_download_event_title: "迎接双12 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/迎接双12 - 活动图包.zip",
        template_download_template_title: "迎接双12-模版图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/迎接双12-模版图包.zip",
        template_download_promotion_title: "迎接双12 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/迎接双12 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 3,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "聖誕小玛莉",
        template_intro: "圣诞转好礼 暖冬大礼遇",
        template_link: "E03",
        template_tab2: E03Tab2,
        template_tag: "",
        template_download_event_title: "圣诞转好礼 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/圣诞转好礼 - 活动图包.zip",
        template_download_template_title: "圣诞转好礼 - 模版图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/圣诞转好礼 - 模版图包.zip",
        template_download_promotion_title: "圣诞转好礼 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/圣诞转好礼 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 4,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "迎春小瑪莉",
        template_intro: "千万金喜迎新年 迎春致富大发财",
        template_link: "E04",
        template_tab2: E04Tab2,
        template_tag: "",
        template_download_event_title: "迎春小玛莉 -活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/迎春小玛莉 -活动图包.zip",
        template_download_template_title: "迎春小玛莉 - 模版图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/迎春小玛莉 - 模版图包.zip",
        template_download_promotion_title: "迎春小玛莉 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/迎春小玛莉 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 5,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "新春刮刮乐",
        template_intro: "新春刮刮乐 刮出发财金",
        template_link: "E05",
        template_tab2: E05Tab2,
        template_tag: "",
        template_download_event_title: "新春刮刮乐 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/新春刮刮乐 - 活动图包.zip",
        template_download_template_title: "新春刮刮乐 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/新春刮刮乐 - 活动页全图包.zip",
        template_download_promotion_title: "新春刮刮乐 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/新春刮刮乐 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 6,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "爱神刮刮乐",
        template_intro: "爱神刮刮乐 一手刮大礼",
        template_link: "E06",
        template_tab2: E06Tab2,
        template_tag: "",
        template_download_event_title: "爱神刮刮乐 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/爱神刮刮乐 - 活动图包.zip",
        template_download_template_title: "爱神刮刮乐 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/爱神刮刮乐 - 活动页全图包.zip",
        template_download_promotion_title: "爱神刮刮乐 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/爱神刮刮乐 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 7,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "元宵刮刮乐",
        template_intro: "元宵刮刮乐 一刮好运来",
        template_link: "E07",
        template_tab2: E07Tab2,
        template_tag: "",
        template_download_event_title: "元宵刮刮乐 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/元宵刮刮乐 - 活动图包.zip",
        template_download_template_title: "元宵刮刮乐 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/元宵刮刮乐 - 活动页全图包.zip",
        template_download_promotion_title: "元宵刮刮乐 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/元宵刮刮乐 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 8,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "礼物树",
        template_intro: "钜惠感谢祭 感恩相伴 感谢有礼",
        template_link: "E08",
        template_tab2: E08Tab2,
        template_tag: "",
        template_download_event_title: "礼物树 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/礼物树 - 活动图包.zip",
        template_download_template_title: "礼物树 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/礼物树 - 活动页全图包.zip",
        template_download_promotion_title: "礼物树 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/礼物树 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 9,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万糖果树",
        template_intro: "百万糖果树 玩糖果 享回馈",
        template_link: "E09",
        template_tab2: E09Tab2,
        template_tag: "",
        template_download_event_title: "百万糖果树 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/百万糖果树 - 活动图包.zip",
        template_download_template_title: "百万糖果树 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/百万糖果树 - 活动页全图包.zip",
        template_download_promotion_title: "百万糖果树 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/百万糖果树 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 10,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万摇钱树",
        template_intro: "百万摇钱树 天天金喜得财富",
        template_link: "E10",
        template_tab2: E10Tab2,
        template_tag: "",
        template_download_event_title: "百万摇钱树 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/百万摇钱树 - 活动图包.zip",
        template_download_template_title: "百万摇钱树 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/百万摇钱树 - 活动页全图包.zip",
        template_download_promotion_title: "百万摇钱树 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/百万摇钱树 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 11,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万幸运转轮",
        template_intro: "玩转手气 好运连连",
        template_link: "E11",
        template_tab2: E11Tab2,
        template_tag: "",
        template_download_event_title: "幸运轮盘 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/幸运轮盘 - 活动图包.zip",
        template_download_template_title: "幸运轮盘 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/幸运轮盘 - 活动页全图包.zip",
        template_download_promotion_title: "幸运轮盘 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/幸运轮盘 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 12,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "海盜大秘宝",
        template_intro: "百万奖金抽不完",
        template_link: "E12",
        template_tab2: E12Tab2,
        template_tag: "",
        template_download_event_title: "海盗轮盘 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/海盗轮盘 - 活动图包.zip",
        template_download_template_title: "海盗轮盘 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/海盗轮盘 - 活动页全图包.zip",
        template_download_promotion_title: "海盗轮盘 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/海盗轮盘 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 13,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "白金幸运转轮",
        template_intro: "免费拼手气 转出百万奖励",
        template_link: "E13",
        template_tab2: E13Tab2,
        template_tag: "",
        template_download_event_title: "白金轮盘 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/白金轮盘 - 活动图包.zip",
        template_download_template_title: "白金轮盘 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/白金轮盘 - 活动页全图包.zip",
        template_download_promotion_title: "白金轮盘 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/白金轮盘 - 广宣素材.zip",
        template_side_ad: ["05", "03", "02", "06"],
    },
    {
        template_id: 14,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "5.1 劳动节",
        template_intro: "回馈耕耘高豐收",
        template_link: "E14",
        template_tab2: E14Tab2,
        template_tag: "",
        template_download_event_title: "5.1劳动节 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/5.1劳动节 - 活动图包.zip",
        template_download_template_title: "5.1劳动节 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/5.1劳动节 - 活动页全图包.zip",
        template_download_promotion_title: "5.1劳动节 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/5.1劳动节 - 广宣素材.zip",
        template_side_ad: ["15", "16", "13", "09"],
    },
    {
        template_id: 15,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万黄金传说",
        template_intro: "勇闯宝藏之境",
        template_link: "E15",
        template_tab2: E15Tab2,
        template_tag: "",
        template_download_event_title: "百万传说 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/百万传说 - 活动图包.zip",
        template_download_template_title: "百万传说 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/百万传说 - 活动页全图包.zip",
        template_download_promotion_title: "百万传说 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/百万传说 - 广宣素材.zip",
        template_side_ad: ["14", "10", "01", "12"],
    },
    {
        template_id: 16,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万蜂赏捞金",
        template_intro: "勤奋犒赏百万金",
        template_link: "E16",
        template_tab2: E16Tab2,
        template_tag: "",
        template_download_event_title: "蜂赏捞金 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/蜂赏捞金 - 活动图包.zip",
        template_download_template_title: "蜂赏捞金 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/蜂赏捞金 - 活动页全图包.zip",
        template_download_promotion_title: "蜂赏捞金 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/蜂赏捞金 - 广宣素材.zip",
        template_side_ad: ["01", "05", "08", "15"],
    },
    // 05/14新增
    {
        template_id: 17,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "端午情至",
        template_intro: "威风龙舟粽百万",
        template_link: "E17",
        template_tab2: E17Tab2,
        template_tag: "",
        template_download_event_title: "端午情至 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/端午情至 - 活动图包.zip",
        template_download_template_title: "端午情至 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/端午情至 - 活动页全图包.zip",
        template_download_promotion_title: "端午情至 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/端午情至 - 广宣素材.zip",
        template_side_ad: ["18", "19", "16", "15"],
    },
    {
        template_id: 18,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "端午粽奖",
        template_intro: "万粽瞩目 包你中大奖",
        template_link: "E18",
        template_tab2: E18Tab2,
        template_tag: "hot",
        template_download_event_title: "端午粽奖 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/端午粽奖 - 活动图包.zip",
        template_download_template_title: "端午粽奖 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/端午粽奖 - 活动页全图包.zip",
        template_download_promotion_title: "端午粽奖 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/端午粽奖 - 广宣素材.zip",
        template_side_ad: ["17", "19", "16", "15"],
    },
    {
        template_id: 19,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "百万红包",
        template_intro: "百万红包大放送 天天加码送奖金",
        template_link: "E19",
        template_tab2: E19Tab2,
        template_tag: "",
        template_download_event_title: "百万红包 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/百万红包 - 活动图包.zip",
        template_download_template_title: "百万红包 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/百万红包 - 活动页全图包.zip",
        template_download_promotion_title: "百万红包 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/百万红包 - 广宣素材.zip",
        template_side_ad: ["16", "10", "12", "13"],
    },
    {
        template_id: 20,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "金球入门",
        template_intro: "金球入门 奖金入手",
        template_link: "E20",
        template_tab2: E20Tab2,
        template_tag: "",
        template_download_event_title: "金球入门 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/金球入门 - 活动图包.zip",
        template_download_template_title: "金球入门 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/金球入门 - 活动页全图包.zip",
        template_download_promotion_title: "金球入门 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/金球入门 - 广宣素材.zip",
        template_side_ad: ["15", "16", "13", "09"],
    },
    // 07/09新增
    {
        template_id: 21,
        template_category: "joy",
        template_category_name: "趣味模版",
        template_title: "金喜蛋",
        template_intro: "蛋蛋有礼! 惊喜敲敲来",
        template_link: "E21",
        template_tab2: E21Tab2,
        template_tag: "hot",
        template_download_event_title: "金喜蛋 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/金喜蛋 - 活动图包.zip",
        template_download_template_title: "金喜蛋 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/金喜蛋 - 活动页全图包.zip",
        template_download_promotion_title: "金喜蛋 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/金喜蛋 - 广宣素材.zip",
        template_side_ad: ["22", "01", "09", "11"],
    },
    {
        template_id: 22,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "七夕情缘",
        template_intro: "七夕情缘金喜来 ",
        template_link: "E22",
        template_tab2: E22Tab2,
        template_tag: "new",
        template_download_event_title: "七夕情缘 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/七夕情缘 - 活动图包.zip",
        template_download_template_title: "七夕情缘 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/七夕情缘 - 活动页全图包.zip",
        template_download_promotion_title: "七夕情缘 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/七夕情缘 - 广宣素材.zip",
        template_side_ad: ["06", "08", "13", "16"],
    },
    // 07/29新增
    {
        template_id: 23,
        template_category: "fishing",
        template_category_name: "捕鱼模版",
        template_title: "百万捕鱼",
        template_intro: "百万奖潮来袭 喜迎财宝一炮爆富",
        template_link: "E23",
        template_tab2: E23Tab2,
        template_tag: "new",
        template_download_event_title: "百万捕鱼 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/百万捕鱼 - 活动图包.zip",
        template_download_template_title: "百万捕鱼 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/百万捕鱼 - 活动页全图包.zip",
        template_download_promotion_title: "百万捕鱼 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/百万捕鱼 - 广宣素材.zip",
        template_side_ad: ["21", "16", "01", "10"],
    },
    {
        template_id: 24,
        template_category: "festival",
        template_category_name: "节庆模版",
        template_title: "中秋博饼",
        template_intro: "中秋博饼 狀元金花拼百万",
        template_link: "E24",
        template_tab2: E24Tab2,
        template_tag: "new",
        template_download_event_title: "中秋博饼 - 活动图包.zip",
        template_download_event_link: "https://www.bbin-news.com/archives/中秋博饼 - 活动图包.zip",
        template_download_template_title: "中秋博饼 - 活动页全图包.zip",
        template_download_template_link: "https://www.bbin-news.com/archives/中秋博饼 - 活动页全图包.zip",
        template_download_promotion_title: "中秋博饼 - 广宣素材.zip",
        template_download_promotion_link: "https://www.bbin-news.com/archives/中秋博饼 - 广宣素材.zip",
        template_side_ad: ["19", "08", "13", "15"],
    },
];

export default TemplateItemsData;
