import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const LoadPageToTop = () => {
    const [previousPath, setPreviousPath] = useState(null); //保存上一頁路徑
    const {pathname: currentPath} = useLocation();

    // 將current這一頁的路徑值，設定設定為上一頁
    // 簡易版的history
    useEffect(() => {
        setPreviousPath(currentPath);
    }, [currentPath]);

    useEffect(() => {
        const currentPathArray = currentPath ? currentPath.split("/") : []; //將目前網址拆成陣列，沒值就給我空陣列
        const prevPathArray = previousPath ? previousPath.split("/") : []; //將上一頁網址拆成陣列，沒值就給我空陣列
        // 當頁面切換都要滾動置頂
        if (true) {
            // currentPathArray[2] === prevPathArray[2] 代表 template/『E01』/intro，E01 在自己內容切換，都不用置頂，但是有可能發生: prevPathArray[2]兩個值都不存在，結果就相等了...
            // 所以再設定一個條件: 確認 prevPathArray[2]或是 currentPathArray[2] 有值，即可避免掉 undefined = undefined 了
            if (prevPathArray[2] && currentPathArray[2] === prevPathArray[2]) {
                return;
            }
            window.scrollTo(0, 0);
        }
    }, [currentPath]);

    return null; // 返回 null，因为不需要渲染任何内容
};

export default LoadPageToTop;
