const ServiceData = [
    {
        id: 1,
        decorationNumber: "01",
        title: "支持层级、等级、黑名单领奖设定",
        info: "将享受到<span>个性化的门槛</span>与红包福利外，可<span>屏蔽不受欢迎</span>的会员，确保游戏环境的纯净和公平。",
    },
    {
        id: 2,
        decorationNumber: "02",
        title: "支持设立存款条件、稽核出款条件",
        info: "突破4门槛限制，可<span>灵活设置多达10个门槛条件、存款条件、稽核出款条件</span>，满足不同类型活动的需求。",
    },
    {
        id: 3,
        decorationNumber: "03",
        title: "无需申请快速建立，自由操作刺激盈利",
        info: "您可以根据营运需求<span>举办活动或随时停止活动</span>，使其更加灵活！",
    },
    {
        id: 4,
        decorationNumber: "04",
        title: "灵活预算设定功能",
        info: "根据不同情况<span>随时调整总预算和每日预算</span>，确保奖金支出在可控的范围内。",
    },
];

export default ServiceData;
