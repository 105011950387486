import {Link} from "react-router-dom";
import {useEffect, useContext} from "react";
import {StoreContext} from "../pages/StoreContext";
import {faCommentsDollar} from "@fortawesome/free-solid-svg-icons";

// template data
import TemplateItemsData from "../assets/data/TemplateItemsData";

function TemplateSideBar({templateLink, handleTabClick, handleScroll, activeSection, handleAnchorScroll}) {
    // templateLink 是由 useParams 抓的值，送進來，才知道正確頁面的錨點
    const {useGtmSender} = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    const modelItem = TemplateItemsData.find((item) => item.template_link === templateLink); //current model
    const sidePromoteItems = modelItem.template_side_ad;

    return (
        <>
            <div className="template-side-bar">
                <div className="sticky">
                    <div className="link-wraper">
                        <div className="title">图包说明</div>
                        <ul>
                            <li>
                                <Link
                                    to={`/template/${templateLink}/intro`}
                                    spy="true"
                                    smooth="true"
                                    duration={800}
                                    className={activeSection === "section1" ? "active" : ""}
                                    onClick={() => {
                                        handleTabClick(1);
                                        setTimeout(() => {
                                            handleScroll(1, "templateDownload");
                                            handleAnchorScroll();
                                        }, 100);
                                        handleGtmClick({
                                            event: "模版頁_錨點",
                                            model: `${modelItem.template_link}`,
                                            model_name: `${modelItem.template_title}`,
                                            model_category: `${modelItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                            memo: "下載專區",
                                        });
                                    }}>
                                    下载专区
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={`/template/${templateLink}/intro`}
                                    spy="true"
                                    smooth="true"
                                    duration={800}
                                    className={activeSection === "section2" ? "active" : ""}
                                    onClick={() => {
                                        handleTabClick(1);
                                        setTimeout(() => {
                                            handleScroll(1, "templateUploadIntro");
                                            handleAnchorScroll();
                                        }, 100);
                                        handleGtmClick({
                                            event: "模版頁_錨點",
                                            model: `${modelItem.template_link}`,
                                            model_name: `${modelItem.template_title}`,
                                            model_category: `${modelItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                            memo: "修改圖包上傳說明",
                                        });
                                    }}>
                                    修改图包上传说明
                                </Link>
                            </li>

                            <li>
                                <Link
                                    to={`/template/${templateLink}/intro`}
                                    spy="true"
                                    smooth="true"
                                    duration={800}
                                    className={activeSection === "section3" ? "active" : ""}
                                    onClick={() => {
                                        handleTabClick(1);
                                        setTimeout(() => {
                                            handleScroll(1, "templateBannerDownload");
                                            handleAnchorScroll();
                                        }, 100);
                                        handleGtmClick({
                                            event: "模版頁_錨點",
                                            model: `${modelItem.template_link}`,
                                            model_name: `${modelItem.template_title}`,
                                            model_category: `${modelItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                            memo: "推廣素材下載",
                                        });
                                    }}>
                                    推广素材下载
                                </Link>
                            </li>
                        </ul>
                        <div className="title">门槛设定说明</div>
                        <ul>
                            <li>
                                <Link
                                    to={`/template/${templateLink}/setting`}
                                    spy="true"
                                    smooth="true"
                                    duration={800}
                                    className={activeSection === "section4" ? "active" : ""}
                                    onClick={() => {
                                        handleTabClick(2);
                                        setTimeout(() => {
                                            handleScroll(2, "templateSetting");
                                            handleAnchorScroll();
                                        }, 100);
                                        handleGtmClick({
                                            event: "模版頁_錨點",
                                            model: `${modelItem.template_link}`,
                                            model_name: `${modelItem.template_title}`,
                                            model_category: `${modelItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                            memo: "獎金說明",
                                        });
                                    }}>
                                    奖金模型
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div className="title title-top">相关活动</div>
                    <ul>
                        {sidePromoteItems.map((value, id) => {
                            return (
                                <li key={id}>
                                    <Link
                                        to={`/template/E${value}/intro`}
                                        onClick={() => {
                                            const gtmModel = `E${value}`;
                                            const gtmModelName = TemplateItemsData.find(
                                                (item) => item.template_link === gtmModel
                                            );
                                            handleGtmClick({
                                                event: "模版頁_相關活動",
                                                model: gtmModel,
                                                model_name: gtmModelName.template_title,
                                                breadcrumb: "模版頁",
                                            });
                                        }}>
                                        <img src={`../images/template/side_ad/${value}.jpg`} alt="" className="full" />
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TemplateSideBar;
