const SliderData = [
    {
        slider_id: 1,
        slider_img: "./images/slider/slider01.jpg",
        slider_link: "/template/E21/intro", ///faq/campaign-preview-link/feature-preview-online
        slider_template_element: "E21",
        slider_template_title: "金喜蛋",
        slider_template_category_name: "趣味模版",
        //如果需要連到faq, 則要在這邊填寫參數
        category_id: 0,
    },
    {
        slider_id: 2,
        slider_img: "./images/slider/slider02.jpg",
        slider_link: "/template/E24/intro",
        slider_template_element: "E24",
        slider_template_title: "中秋博饼",
        slider_template_category_name: "节庆模版",
        //for faq link
        category_id: 0,
    },
    {
        slider_id: 3,
        slider_img: "./images/slider/slider03.jpg",
        slider_link: "/template/E15/intro",
        slider_template_element: "E15",
        slider_template_title: "百万黄金传说",
        slider_template_category_name: "趣味模版",
        //for faq link
        category_id: 0,
    },
];

export default SliderData;
