import {Link, useLocation} from "react-router-dom";
import LazyLoad from "react-lazyload";
import {useContext, useState} from "react";
import {StoreContext} from "../pages/StoreContext";

// 顯示template items
function TemplateGroup({selectedName, templateContents}) {
    const {useGtmSender} = useContext(StoreContext);
    const {pathname} = useLocation();
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    let event = "";
    let breadcrumb = "";

    if (pathname === "/") {
        // 抓首頁
        event = "首頁_推薦模版";
        breadcrumb = "首頁";
    } else if (pathname === "/template") {
        // 抓的是內頁的
        event = "更多模版_模版點擊";
        breadcrumb = "更多模版";
    }

    return (
        <>
            <div className="content-wrap">
                <ul>
                    {templateContents.map((item, index) => {
                        // 判斷 添加hot、new標籤
                        const hotTag = item.template_tag;
                        let showTag = <></>;
                        if (hotTag === "hot") {
                            showTag = (
                                <>
                                    <img
                                        src={"./images/template/template_icon_hot.png"}
                                        alt="hotTag"
                                        className="tag-img"
                                    />
                                </>
                            );
                        } else if (hotTag === "new") {
                            showTag = (
                                <>
                                    <img
                                        src={"./images/template/template_icon_new.png"}
                                        alt="newTag"
                                        className="tag-img"
                                    />
                                </>
                            );
                        }
                        return (
                            <LazyLoad key={item.template_id} height={250} offset={250}>
                                <li key={item.template_id}>
                                    {showTag}
                                    <Link
                                        to={`/template/${item.template_link}/intro`}
                                        onClick={() => {
                                            handleGtmClick({
                                                event,
                                                breadcrumb,
                                                model: `${item.template_link}`,
                                                model_name: `${item.template_title}`,
                                                model_category: `${item.template_category_name}`,
                                                model_category_selected: selectedName
                                                    ? "全部模板"
                                                    : `${item.template_category_name}`,
                                            });
                                        }}>
                                        <div
                                            className="template-img-wrap"
                                            style={{
                                                backgroundImage: `url(./images/template/${item.template_link}/list_img.jpg)`,
                                            }}></div>
                                        <div className="intro">
                                            <div className="category">{item.template_category_name}</div>
                                            <div className="title-name">{item.template_title}</div>
                                            <div className="info">{item.template_intro}</div>
                                        </div>
                                    </Link>
                                </li>
                            </LazyLoad>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default TemplateGroup;
