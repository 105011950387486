// scss
import "../index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// component
import TemplateSideBar from "../../../components/TemplateSideBar";
import TemplateIntro from "../../../components/TemplateIntro";
import LightWindow from "../../../components/LightWindow";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useState, useEffect, useContext} from "react";
import {StoreContext} from "../../StoreContext";
import {debounce} from "lodash";
// template data
import TemplateItemsData from "../../../assets/data/TemplateItemsData";

export const TemplateDetailContent = (props) => {
    const {useGtmSender} = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    const navigate = useNavigate();
    const {pathname} = useLocation(); //抓取目前頁面路徑
    const {tabName} = useParams(); //抓路徑值使用，會去掉faq/之後的值

    //幫我判斷路由名稱是否為 intro 或是 setting
    let checkTemplateTabPath = tabName === "intro" || tabName === "setting";
    let checkNotFound = false;

    //假如 tabName不是空字串，再去判斷 tabName是否為 intro or setting
    if (typeof tabName !== "undefined") {
        if (!checkTemplateTabPath) {
            checkNotFound = true;
        }
    }
    useEffect(() => {
        // 不符合条件就倒页
        if (checkNotFound) {
            // 当 templateTabPath 不是上面3種情況，幫我倒頁
            navigate("/not-found");
        }
    }, [navigate, pathname, checkNotFound]);

    const match = pathname.match(/\/template\/([^/]+)/);
    const templateLink = match ? match[1] : null; //模板名稱

    // 比對正確資料，抓出來給組件參數使用
    const templateItem = TemplateItemsData.find((item) => item.template_link === templateLink);

    //偵測錨點範圍
    const [activeSection, setActiveSection] = useState("");

    const handleAnchorScroll = () => {
        const scrollPosition = window.scrollY; //視窗滾動的高度

        //抓位置 & 調整偏移量;
        const sectionOffsets = {
            // 4個錨點定位
            section1: document.getElementById("templateDownload")
                ? document.getElementById("templateDownload").offsetTop - 200
                : 0,
            section2: document.getElementById("templateUploadIntro")
                ? document.getElementById("templateUploadIntro").offsetTop - 200
                : 0,

            section3: document.getElementById("templateBannerDownload")
                ? document.getElementById("templateBannerDownload").offsetTop - 200
                : 0,
            section4: document.getElementById("templateSetting")
                ? document.getElementById("templateSetting").offsetTop - 200
                : 0,
        };

        // 找到目前位於視窗內的區塊
        let active = "";
        Object.keys(sectionOffsets).forEach((section) => {
            if (sectionOffsets[section] !== 0 && scrollPosition >= sectionOffsets[section]) {
                active = section;
            } else if (sectionOffsets[section] === 0) {
                return;
            }
        });

        // 更新active區塊的狀態
        setActiveSection(active);
    };
    useEffect(() => {
        window.addEventListener("scroll", handleAnchorScroll);
        return () => {
            window.removeEventListener("scroll", handleAnchorScroll);
        };
    }, []);

    // 錨點功能
    const handleScroll = (tabNumber, elementId) => {
        const navTemplateElement = document.getElementById(elementId);
        setActiveTab(tabNumber);
        if (navTemplateElement) {
            window.scrollTo({
                top: navTemplateElement.offsetTop,
                behavior: "smooth",
            });
        }
        // 添加路由切換邏輯
        handleTabClick(tabNumber);
    };

    // tab功能
    const [activeTab, setActiveTab] = useState(1);
    // 預設：判斷路徑幫我切換tab
    if (tabName === "intro" && activeTab !== 1) {
        setActiveTab(1);
    } else if (tabName === "setting" && activeTab !== 2) {
        setActiveTab(2);
    }
    const handleTabClick = (tabNumber) => {
        if (tabNumber === 1) {
            navigate(`/template/${templateItem.template_link}/intro`);
        } else if (tabNumber === 2) {
            navigate(`/template/${templateItem.template_link}/setting`);
        }
    };

    // light-window 功能
    const [overlay, setOverlay] = useState(false);
    const handleAdLightWindow = () => {
        // 打開彈窗
        setOverlay(true);
    };

    // gtm高度
    useEffect(() => {
        let scrollHandler = null;
        const container = window;
        const content = document.body;
        const gtmStatus = {
            "25%": false,
            "50%": false,
            "75%": false,
            "100%": false,
        };

        const gtmHandler = ({percent = "25%", e}) => {
            const part = tabName === "intro" ? "圖包說明" : "門檻設定說明";

            handleGtmClick({
                event: `模版頁_${part}_滾動${percent}`,
                model: `${templateItem.template_link}`,
                model_name: `${templateItem.template_title}`,
                model_category: `${templateItem.template_category_name}`,
                breadcrumb: "模版頁",
            });
            gtmStatus[percent] = true;
            if (percent === "100%") e.target.removeEventListener("scroll", scrollHandler);
        };

        scrollHandler = debounce((e) => {
            const basicHeight = Math.round(window.scrollY + window.innerHeight);
            // console.log(basicHeight, content.offsetHeight);

            if (basicHeight >= content.offsetHeight * 0.25 && !gtmStatus["25%"]) {
                gtmHandler({percent: "25%", e});
            }
            if (basicHeight >= content.offsetHeight * 0.5 && !gtmStatus["50%"]) {
                gtmHandler({percent: "50%", e});
            }
            if (basicHeight >= content.offsetHeight * 0.75 && !gtmStatus["75%"]) {
                gtmHandler({percent: "75%", e});
            }
            if (basicHeight >= content.offsetHeight && !gtmStatus["100%"]) {
                gtmHandler({percent: "100%", e});
            }
        }, 100);

        container.addEventListener("scroll", scrollHandler);
        return () => {
            container.removeEventListener("scroll", scrollHandler);
        };
    }, [
        tabName,
        templateItem.template_category_name,
        templateItem.template_link,
        templateItem.template_title,
        useGtmSender,
    ]);
    return (
        <>
            <div className="template-detail-wrap">
                <div className="template-detail-content clearfix">
                    <div className="template-side-intro">
                        {/* intro 最上方的介紹 */}
                        <TemplateIntro templateItem={templateItem} />

                        {/* tab */}
                        <div className="template-tab-item">
                            <div className="tabs-title clearfix">
                                <div
                                    className={`title ${activeTab === 1 ? "active" : ""}`}
                                    onClick={() => {
                                        handleTabClick(1);
                                        handleGtmClick({
                                            event: "模版頁_圖包說明",
                                            model: `${templateItem.template_link}`,
                                            model_name: `${templateItem.template_title}`,
                                            model_category: `${templateItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                        });
                                    }}>
                                    图包说明
                                </div>
                                <div
                                    className={`title ${activeTab === 2 ? "active" : ""}`}
                                    onClick={() => {
                                        handleTabClick(2);
                                        handleGtmClick({
                                            event: "模版頁_門檻設定說明",
                                            model: `${templateItem.template_link}`,
                                            model_name: `${templateItem.template_title}`,
                                            model_category: `${templateItem.template_category_name}`,
                                            breadcrumb: "模版頁",
                                        });
                                    }}>
                                    门槛设定说明
                                </div>
                            </div>
                            <div className="tabs-content">
                                {activeTab === 1 && (
                                    <Tab1Content
                                        handleAdLightWindow={handleAdLightWindow}
                                        templateItem={templateItem}
                                    />
                                )}
                                {activeTab === 2 && <Tab2Content templateItem={templateItem} />}
                            </div>
                        </div>
                    </div>

                    {/* 側邊欄 */}
                    <TemplateSideBar
                        templateLink={templateLink}
                        handleTabClick={handleTabClick}
                        handleScroll={handleScroll}
                        // 判斷錨點範圍
                        activeSection={activeSection}
                        handleAnchorScroll={handleAnchorScroll}
                    />
                </div>
            </div>
            {/* 彈窗 */}
            <LightWindow overlay={overlay} setOverlay={setOverlay} templateLink={templateLink} />
        </>
    );
};

const Tab1Content = ({handleAdLightWindow, templateItem}) => {
    const {useGtmSender} = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };
    const {
        template_link,
        template_title,
        template_category_name,
        template_download_event_link,
        template_download_event_title,
        template_download_template_link,
        template_download_template_title,
        template_download_promotion_link,
        template_download_promotion_title,
    } = templateItem;
    return (
        <>
            {/* 下载专区 */}
            <div id="templateDownload" className="template-side-item">
                <div className="title">
                    <h3>下载专区</h3>
                </div>
                <div className="content">
                    <ul className="download">
                        <li>
                            <a
                                href={`${template_download_event_link}`}
                                download={`${template_download_event_title}`}
                                onClick={() => {
                                    handleGtmClick({
                                        event: "模版頁_圖包說明_重點圖包",
                                        model: `${template_link}`,
                                        model_name: `${template_title}`,
                                        model_category: `${template_category_name}`,
                                        breadcrumb: "模版頁",
                                    });
                                }}>
                                <FontAwesomeIcon icon="fa-regular fa-folder-open" className="icon" />
                                <span>重点图包</span>
                                <i>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                                </i>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${template_download_template_link}`}
                                download={`${template_download_template_title}`}
                                onClick={() => {
                                    handleGtmClick({
                                        event: "模版頁_圖包說明_活動頁全圖包",
                                        model: `${template_link}`,
                                        model_name: `${template_title}`,
                                        model_category: `${template_category_name}`,
                                        breadcrumb: "模版頁",
                                    });
                                }}>
                                <FontAwesomeIcon icon="fa-regular fa-image" className="icon" />
                                <span>活动页全图包</span>
                                <i>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                                </i>
                            </a>
                        </li>
                        <li>
                            <a
                                href={`${template_download_promotion_link}`}
                                download={`${template_download_promotion_title}`}
                                onClick={() => {
                                    handleGtmClick({
                                        event: "模版頁_圖包說明_廣宣素材",
                                        model: `${template_link}`,
                                        model_name: `${template_title}`,
                                        model_category: `${template_category_name}`,
                                        breadcrumb: "模版頁",
                                    });
                                }}>
                                <FontAwesomeIcon icon="fa-solid fa-bullhorn" className="icon" />
                                <span>广宣素材</span>
                                <i>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                                </i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* 修改图包上传说明 */}
            <div id="templateUploadIntro" className="template-side-item">
                <div className="title">
                    <h3>修改图包上传说明</h3>
                </div>
                <div className="content">
                    <p>请您需先完成活动建立后，再依照以下步骤进行图包的更改哦</p>

                    <p className="title">【步骤一】依据需求下载图包后调整您想要的图片</p>

                    <p>上方下载专区有提供两种图包给您参考应用，以下说明差异</p>

                    <p className="step">
                        <span className="num">1</span>
                        <span>重点图包(附PSD)</span>
                    </p>

                    <p>
                        共有以下5张图片，附有<span>PSD编辑档</span>方便您编辑使用
                    </p>
                    <p>
                        <img src={`../images/template/${template_link}/tab1_intro01.png`} alt="" />
                    </p>

                    <p className="step">
                        <span className="num">2</span>
                        <span>活动页全图包 (范例为示意图，非本模版)</span>
                    </p>

                    <p>您下载后可自由替换图包内容，您可以依照您的活动需求设计出独一无二的模版活动</p>
                    <p>
                        <img src={`../images/template/default/tab1_intro02.jpg`} alt="" />
                    </p>

                    <p>
                        需注意：修改的图档
                        <span>文件名、格式、尺寸都要一致且档案大小不要太大</span> 喔，否则替换图包会有问题
                        <br />
                        您可于图片右键点选『内容』了解该图片的名称、格式、尺寸
                    </p>

                    <p>
                        <img src={`../images/template/default/tab1_intro03.jpg`} alt="" />
                    </p>

                    <p className="title">【步骤二】后台上传（要先完成活动创建才可以进行图包上传喔）</p>
                    <p>您于后台先上传完毕后，会出现『上传成功』的字样</p>

                    <p>
                        <img src={`../images/template/default/tab1_intro04.jpg`} alt="" className="full" />
                    </p>

                    <p>
                        此时可点选『预览』，查看活动页图包更新状况
                        <br />
                        <span>（预览不会连动您的活动时间、门槛或奖金等设定显示，只提供图包变化检视）</span>
                    </p>
                    <p>
                        <img src={`../images/template/default/tab1_intro05.jpg`} alt="" />
                    </p>
                    <p>
                        <img src={`../images/template/default/tab1_intro06.jpg`} alt="" />
                    </p>

                    <p>
                        <span>确认无误后按下『发布』，再点击模块右上角的『储存』</span>
                        ；即可完成活动页图包更换
                    </p>
                    <p>
                        <img src={`../images/template/default/tab1_intro07.jpg`} alt="" className="full" />
                    </p>
                </div>
            </div>

            {/* 推广素材下载 */}
            <div id="templateBannerDownload" className="template-side-item">
                <div className="title">
                    <h3>推广素材下载</h3>
                </div>
                <div className="content">
                    <p>
                        提供您
                        <a
                            href={`${template_download_promotion_link}`}
                            download={`${template_download_promotion_title}`}
                            className="download-link"
                            onClick={() => {
                                handleGtmClick({
                                    event: "模版頁_圖包說明_下載廣宣素材",
                                    model: `${template_link}`,
                                    model_name: `${template_title}`,
                                    model_category: `${template_category_name}`,
                                    breadcrumb: "模版頁",
                                });
                            }}>
                            下载广宣素材
                        </a>
                        (附PSD编辑档)，供您编辑后进行宣传与曝光。
                    </p>
                    <a
                        href="###"
                        onClick={(e) => {
                            e.preventDefault();
                            handleAdLightWindow();
                        }}>
                        <img src={`../images/template/${template_link}/promotion_img.jpg`} alt="" className="full" />
                    </a>
                </div>
            </div>
        </>
    );
};
const Tab2Content = ({templateItem}) => {
    const {template_link, template_tab2: Tab2Content} = templateItem;
    // const {template_link} = templateItem;
    return (
        <>
            {/* 门槛设定说明 */}
            <div id="templateSetting" className="template-side-item">
                <Tab2Content template_link={template_link} />
            </div>
        </>
    );
};
