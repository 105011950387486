import {useState, useEffect, useContext} from "react";
import {useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StoreContext} from "../pages/StoreContext";

import TemplateItemsData from "../assets/data/TemplateItemsData";

const GoToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [scrollHeight, setScrollHeight] = useState(0);

    const {useGtmSender} = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };
    const {pathname} = useLocation();

    const top = () => {
        if (document.getElementById("container")) {
            document.getElementById("container").scrollTo({top: 0, left: 0, behavior: "smooth"});
        } else {
            window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        }
    };

    const goTop = () => {
        top();

        // gtm
        const getBreadCrumbName = (pathname) => {
            let breadcrumb;

            if (pathname === "/") {
                breadcrumb = "首頁";
            } else if (pathname.includes("/template")) {
                breadcrumb = "模板頁";
            } else if (pathname.includes("/faq")) {
                breadcrumb = "常見問題";
            }
            return breadcrumb;
        };

        const breadcrumb = getBreadCrumbName(pathname);

        if (breadcrumb === "首頁" || breadcrumb === "常見問題") {
            handleGtmClick({
                event: "滾動置頂按鈕",
                breadcrumb,
            });
        } else if (breadcrumb === "模板頁") {
            const match = pathname.match(/\/template\/([^/]+)/);
            const modelItem = match ? match[1] : null; //模板名稱
            const templateItem = TemplateItemsData.find((item) => item.template_link === modelItem);

            // 狀況：如果出現在列表頁面，就會無法抓到 modelItem，當抓不到的時後，就只傳至頂按鈕+麵包屑就好
            if (templateItem) {
                handleGtmClick({
                    event: "滾動置頂按鈕",
                    breadcrumb,
                    model: templateItem.template_link,
                    model_name: templateItem.template_title,
                    model_category: templateItem.template_category_name,
                });
            } else {
                handleGtmClick({
                    event: "滾動置頂按鈕",
                    breadcrumb,
                });
            }
        }
    };

    useEffect(() => {
        function onScroll() {
            const currentPositionHeight = window.scrollY;

            if (currentPositionHeight > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
            setScrollHeight(currentPositionHeight <= 0 ? 0 : currentPositionHeight);
        }

        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [scrollHeight]);

    return (
        <>
            {isVisible && (
                // onClick={top}
                <button
                    className="go-to-top"
                    onClick={() => {
                        goTop();
                    }}>
                    <FontAwesomeIcon icon="fa-solid fa-chevron-up" />
                </button>
            )}
        </>
    );
};
export default GoToTop;
