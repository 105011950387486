import "reset-css";
import "./base.scss";

// Router
import {RouterProvider} from "react-router-dom";
import AppRoutes from "./AppRoutes"; // 引入 AppRoutes

// 全局fontawsome使用
import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";

const App = () => {
    return <RouterProvider router={AppRoutes}></RouterProvider>;
};

export default App;
library.add(fab, fas, far);
