import {useState, useContext} from "react";
import {Link} from "react-router-dom";
import {StoreContext} from "../../pages/StoreContext";

import "./index.scss";

// slider
import HomeSlider from "../HomeSlider";

//template data
import TemplateItemsData from "../../assets/data/TemplateItemsData";
import TemplateGroup from "../TemplateGroup";

//service data
import ServiceData from "../../assets/data/ServiceData";
import ServiceTab from "../ServiceTab";
import ServiceGroup from "../ServiceGroup";

import FaqGroup from "../FaqGroup";

// 首頁在此
export const PageContent = () => {
    const {
        // gtm
        useGtmSender,
    } = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    // 邏輯為：抓最新5筆資料為首頁推薦模板
    const [templateContents, setTemplateContents] = useState(
        [...TemplateItemsData]
            .reverse()
            // .filter((item) => item.template_recommend === "check")
            .slice(0, 5)
    );

    // 服務優點
    const [serviceTabs, setServiceTabs] = useState(ServiceData);
    const [serviceContents, setServiceContents] = useState(ServiceData);
    const [serviceCurrentItem, setServiceCurrentItem] = useState(0);

    return (
        <>
            {/* 輪播圖 */}
            <HomeSlider />

            {/* 推薦模版 */}
            <div id="navTemplate" className="template-wrap">
                <h3>推荐模版</h3>
                <div className="template-content">
                    <TemplateGroup templateContents={templateContents} />
                    <Link
                        to="/template"
                        onClick={() =>
                            handleGtmClick({
                                event: "首頁_更多模版",
                                breadcrumb: "首頁",
                            })
                        }>
                        更多模版
                    </Link>
                </div>
            </div>

            {/* 服務優點 */}
            <div id="navService" className="service-wrap clearfix">
                <h3>服务优点</h3>
                <ServiceTab
                    serviceTabs={serviceTabs}
                    serviceCurrentItem={serviceCurrentItem}
                    setServiceCurrentItem={setServiceCurrentItem}
                />
                <ServiceGroup serviceContents={serviceContents} serviceCurrentItem={serviceCurrentItem} />
            </div>

            {/* 常見問題 */}
            <FaqGroup />
        </>
    );
};
