function PageFooter() {
    return (
        <footer>
            <div className="wrap clearfix">
                <p>Copyrights by BBIN GROUP 2024. All Rights Reserved.</p>
            </div>
        </footer>
    );
}

export default PageFooter;
