// scss
import "./index.scss";

import {Outlet} from "react-router-dom";

import {useState, useContext} from "react";
import {StoreContext} from "../StoreContext";

// components
import useGtmSender from "../../useGtmSender";

export const Faq = () => {
    const {
        // 側邊欄用到的參數
        selectedCategoryId,
        setSelectedCategoryId,
        handleToggle,
    } = useContext(StoreContext);

    // faq search結果和初始資料
    const [searchTerm, setSearchTerm] = useState(""); //input裡的搜尋關鍵字
    const [searchTitle, setSearchTitle] = useState(""); //faq search title
    const [searchResults, setSearchResults] = useState([]); //儲存faq搜尋結果
    const [searching, setSearching] = useState(false); //判斷result有沒有值，沒有值就顯示:未得到結果

    return (
        <>
            <StoreContext.Provider
                value={{
                    // 側邊欄
                    selectedCategoryId,
                    setSelectedCategoryId,
                    handleToggle,
                    // 搜尋
                    searchTerm,
                    setSearchTerm,
                    searchTitle,
                    setSearchTitle,
                    searchResults,
                    setSearchResults,
                    searching,
                    setSearching,
                    // gtm資料
                    useGtmSender,
                }}>
                <Outlet />
            </StoreContext.Provider>
        </>
    );
};
