/**
 * GTM 發送器
 * @param {Object} obj
 * @param {String} obj.event 事件名稱
 * @param {String} obj.model 模組編號
 * @param {String} obj.model_name 模組名稱
 * @param {String} obj.model_category 模組分類
 * @param {String} obj.model_category_selected 當前頁面選中的模組分類
 * @param {String} obj.breadcrumb 麵包屑
 * @param {String} obj.button_text 點擊按鈕
 * @param {String} obj.memo 備註
 *
 */

// 3.0
const useGtmSender = (obj) => {
    const sendData = (obj) => {
        const {dataLayer, location} = window;
        const utm = location.href.match(new RegExp("[?&]utm_source=([^&]+).*$"));
        const apiInfo = {
            event: obj.event || undefined,
            model: obj.model || undefined,
            model_name: obj.model_name || undefined,
            model_category: obj.model_category || undefined,
            model_category_selected: obj.model_category_selected || undefined,
            breadcrumb: obj.breadcrumb || undefined,
            button_text: obj.button_text || undefined,
            memo: obj.memo || undefined,
            utm_source: utm ? utm[1] : undefined,
        };
        dataLayer.push(apiInfo);
        // console.log("GTM_SENDER:");
        // console.log(apiInfo);
    };
    return sendData;
};

export default useGtmSender;
export {useGtmSender};
