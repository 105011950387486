import {Link} from "react-router-dom";
import {useContext} from "react";
import {StoreContext} from "../pages/StoreContext";

const ServiceGroup = ({serviceContents, serviceCurrentItem}) => {
    const {useGtmSender} = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    // 在這邊再把需要的值抓出來，直接用
    const {decorationNumber, title, info} = serviceContents[serviceCurrentItem];

    let eventName = "";
    if (serviceCurrentItem === 0) {
        eventName = "首頁_服務優點_層級設定";
    } else if (serviceCurrentItem === 1) {
        eventName = "首頁_服務優點_存款条件";
    } else if (serviceCurrentItem === 2) {
        eventName = "首頁_服務優點_快速建立";
    } else if (serviceCurrentItem === 3) {
        eventName = "首頁_服務優點_预算设定";
    }
    return (
        <div className="content-wrap">
            <div key={serviceCurrentItem} className={`content content0${serviceCurrentItem + 1}`}>
                <div className="title">
                    <p>{decorationNumber}</p>
                    {title}
                </div>
                {/* <p>{info}</p> */}
                <p dangerouslySetInnerHTML={{__html: info}} />
                <Link
                    to="/template"
                    onClick={() =>
                        handleGtmClick({
                            event: eventName,
                            breadcrumb: "首頁",
                        })
                    }>
                    马上开始
                </Link>
            </div>
        </div>
    );
};

export default ServiceGroup;
