// scss
import {useState} from "react";
import "./index.scss";
import axios from "axios";

const FeedbackForm = ({setSendSucceed, setOverlay}) => {
    const [formData, setFormData] = useState({
        // 一進來這個頁面，表單都清空
        clientName: "", //尊稱
        code: "", //網站後製碼
        category: "", //問題分類
        intro: "", //問題描述
        introImg: null, //圖片
    });

    // 防止連點開關，添加『送出』按鈕樣式
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (e) => {
        const {name, value, files} = e.target;

        // 判斷是否有圖片
        if (name === "introImg" && files.length > 0) {
            // 限定上傳圖片大小
            const file = files[0];
            if (file && file.size > 2 * 1024 * 1024) {
                alert("图片大小超出了2MB 请您压缩后上传");
                // 清空圖片檔案
                e.target.value = null;
                return;
            }
        }
        // 更新表單數據
        setFormData((prevData) => ({...prevData, [name]: value}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // 防止連點，除非try catch處理完畢，才把return關閉
        if (isSubmitting) {
            return;
        }

        try {
            // 打開防止連點
            setIsSubmitting(true);

            const BOT_TOKEN = process.env.REACT_APP_BOT_TOKEN;
            const GROUP_ID = process.env.REACT_APP_TG_GID;

            // tg bot 要送出的表單
            const formDataToSend = new FormData();
            const photoInput = document.getElementById("introImg");
            const photoFile = photoInput.files[0];
            const textString = `${formData.intro}`;
            formData.intro = textString.replace(/\n/g, "、"); // 把textarea裡面的換行符號用其他符號替換掉
            const formDataMsg = `*尊称:* ${formData.clientName} \n*网站后置码:* ${formData.code} \n*问题分类:* ${formData.category} \n*问题内容:* ${formData.intro}`;

            // console.log('${formData.intro}',`${formData.intro}`)

            formDataToSend.append("photo", photoFile); //圖片檔案
            formDataToSend.append("caption", formDataMsg); //圖片描述
            formDataToSend.append("text", formDataMsg); //純文字訊息

            // console.log(formDataToSend.get("photo"));

            // 是否發送圖片
            if (!formData.introImg) {
                // 沒有圖片的話:只發送文字
                const sendMessageToBot = await axios.post(
                    `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage?chat_id=${GROUP_ID}`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        params: {
                            parse_mode: "Markdown", // 指定 parse_mode 為 "Markdown"
                        },
                    }
                );
            } else {
                // 有圖片的話就只送圖
                const sendPhotoToBot = await axios.post(
                    `https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto?chat_id=${GROUP_ID}`,
                    formDataToSend,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        params: {
                            parse_mode: "Markdown", // 指定 parse_mode 為 "Markdown"
                        },
                    }
                );
            }

            // 處理要送給google的表單
            const formDataToGoogle = document.querySelector("form");
            const formDataToSendGoogle = new FormData(formDataToGoogle);
            const GOOGLE_SHEET_ID = process.env.REACT_APP_GOOGLE_SHEET_ID;
            const sendDataToGoogle = await axios.post(
                `https://script.google.com/macros/s/${GOOGLE_SHEET_ID}/exec`,
                formDataToSendGoogle
            );
            // console.log(response.data);

            // 清空表單
            setFormData({
                clientName: "",
                code: "",
                category: "",
                intro: "",
                introImg: null,
            });

            // 成功才能去成功畫面
            setSendSucceed(false);
        } catch (error) {
            // 失敗繼續停留 原本頁面
            setSendSucceed(true);
            // 此處處理錯誤的情況
            console.error("Error:", error);
            alert("传送失败，请重新上传或填写表单");
        } finally {
            // 上面程式碼處理完畢，無論成功還是失敗，都在最終處理後啟用按鈕
            setIsSubmitting(false);
        }
    };
    return (
        <div className="feedback-content">
            <div
                className="feedback-close"
                onClick={() => {
                    setOverlay(false);
                    setSendSucceed(true); //畫面回表單頁
                }}></div>
            <div className="feedback-intro">
                <h3>問題反饋</h3>
                <p>
                    若您对营运红包有任何问题或建议，
                    <br />
                    欢迎填写下方表格，我们将派专人与您联系。
                </p>
            </div>
            <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
                <ul className="feedback-form clearfix">
                    <li className="feedback-name">
                        <label htmlFor="clientName">尊称 *</label>
                        <input
                            id="clientName"
                            name="clientName"
                            type="text"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li className="feedback-code">
                        <label htmlFor="code">
                            网站后置码 * <span>若资讯错误，我们将无法联系您</span>
                        </label>
                        <input
                            id="code"
                            name="code"
                            type="text"
                            value={formData.code}
                            onChange={handleChange}
                            required
                        />
                    </li>
                    <li>
                        <label htmlFor="category">问题分类 *</label>
                        <select
                            id="category"
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            required>
                            <option value="">请选择问题分类</option>
                            <option value="创立活动">创立活动</option>
                            <option value="层级问题">层级问题</option>
                            <option value="活动点数">活动点数</option>
                            <option value="活动预览/连结">活动预览/连结</option>
                            <option value="活动时间">活动时间</option>
                            <option value="活动图包">活动图包</option>
                            <option value="奖金/领奖设定">奖金/领奖设定</option>
                            <option value="活动报表">活动报表</option>
                            <option value="推广相关">推广相关</option>
                            <option value="其他">其他</option>
                        </select>
                    </li>
                    <li>
                        <label htmlFor="intro">问题内容 *</label>
                        <textarea id="intro" name="intro" value={formData.intro} onChange={handleChange} required />
                    </li>
                    <li>
                        <input
                            id="introImg"
                            type="file"
                            name="introImg"
                            onChange={handleChange}
                            accept=".png, .jpg, .jpeg" //僅能接受png,jpg 直接在彈窗不給選噎XD
                        />
                        <span className="upload-intro">※ 图片限制：2 MB / 一张、类型：jpg, png</span>
                        <button className={isSubmitting ? "submitting-style" : ""} type="submit"></button>
                    </li>
                </ul>
            </form>
        </div>
    );
};
export default FeedbackForm;
