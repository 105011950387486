// scss
import "./index.scss";

import {useState, useContext} from "react";
// import {StoreContext} from "../StoreContext";
// // components
// import PageHeader from "../../components/PageHeader";
// import PageFooter from "../../components/PageFooter";
// import GoToTop from "../../components/GoToTop";

//推薦模版: 資料&組件
// import TemplateCategoryData from "../../assets/data/TemplateCategoryData";
import TemplateItemsData from "../../assets/data/TemplateItemsData";
import TemplateTab from "../../components/TemplateTab";
import TemplateGroup from "../../components/TemplateGroup";

const Template = () => {
    // const {test} = useContext(StoreContext);
    // console.log(test);

    // 重組內頁使用的tab陣列，添加“全部模板”
    // const templateCategories = ["全部模版", ...new Set(TemplateItemsData.map((item) => item.template_category_name))];
    const templateCategories = ["全部模版", ...new Set(TemplateItemsData.map((item) => item.template_category_name))];

    // 並且把 分類陣列存成categories，給tab辨別使用
    const [categories, setCategories] = useState(templateCategories);
    // 點選的項目紀錄，紀錄tab active使用
    const [templateCurrentItem, setTemplateCurrentItem] = useState(0);

    //   排序使用
    const sortByTemplateTag = (a, b) => {
        // 將 "new" 排在 "hot" 之前
        if (a.template_tag === "new" && b.template_tag !== "new") {
            return -1;
        } else if (a.template_tag !== "new" && b.template_tag === "new") {
            return 1;
        }

        // 將 "hot" 排在其他之前
        if (a.template_tag === "hot" && b.template_tag !== "hot") {
            return -1;
        } else if (a.template_tag !== "hot" && b.template_tag === "hot") {
            return 1;
        }

        return 0; // 保持原有順序
    };
    // 內頁『預設:全部模板』，全部列出來，
    const [templateContents, setTemplateContents] = useState([...TemplateItemsData].reverse().sort(sortByTemplateTag));

    // gtm使用，判斷篩選項目
    const [selectedName, setSelectedName] = useState(true);
    const filterItems = (category) => {
        // 重新撈資料篩選
        const newPageContents = TemplateItemsData.filter((item) => item.template_category_name === category);
        // 記得最新的要在最上方！所以要反轉reverse
        setTemplateContents([...newPageContents].reverse().sort(sortByTemplateTag));
        setSelectedName(false);

        // 選擇全部模板，則將全部資料塞回去，記得也要反轉！
        if (category === "全部模版") {
            setTemplateContents([...TemplateItemsData].reverse().sort(sortByTemplateTag));
            setSelectedName(true); //判斷為：全部模板
            return;
        }
    };

    return (
        <>
            {/* 推薦模版 */}
            <div className="template-wrap">
                <div className="template-content">
                    <TemplateTab
                        categories={categories}
                        filterItems={filterItems}
                        templateCurrentItem={templateCurrentItem}
                        setTemplateCurrentItem={setTemplateCurrentItem}
                    />
                    <TemplateGroup selectedName={selectedName} templateContents={templateContents} />
                </div>
            </div>
        </>
    );
};

export default Template;
