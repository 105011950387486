import "./index.scss";

// 篩選tab的組件
function TemplateTab({categories, filterItems, templateCurrentItem, setTemplateCurrentItem}) {
    return (
        <>
            <div className="title-wrap">
                <ul>
                    {categories.map((category, index) => {
                        return (
                            <li
                                key={index}
                                className={index === templateCurrentItem ? "active" : ""}
                                onClick={() => {
                                    filterItems(category);
                                    setTemplateCurrentItem(index);
                                }}>
                                {category}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default TemplateTab;
