import "./index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useParams, useNavigate, useLocation} from "react-router-dom";
import {useContext, useEffect} from "react";
import {StoreContext} from "../../StoreContext";

import FaqCategoryList from "../../../assets/data/FaqCategoryList";
import FaqDetailList from "../../../assets/data/FaqDetailList";

const FaqListContent = () => {
    const navigate = useNavigate(); //倒頁使用
    const {pathname} = useLocation(); //抓網址使用
    const {faqPathName} = useParams(); //抓路徑值使用，會去掉faq/之後的值

    let checkNotFound = true; //倒頁開關
    let itemName = FaqCategoryList.find((item) => item.category_link === faqPathName); //大標
    if (!itemName) {
        // 當findItem是undefined時，幫我塞空物件，並且開啟倒頁開關
        itemName = {};
        checkNotFound = false;
    }

    useEffect(() => {
        // 不符合條件就倒頁;
        if (!checkNotFound) {
            navigate("/not-found");
        }
    }, [faqPathName, pathname, navigate, itemName, checkNotFound]); //faqPathName

    const itemList = FaqDetailList.filter((item) => item.category_id === itemName.category_id); //小標

    const {
        // gtm
        useGtmSender,
    } = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    return (
        <>
            <div className="faq-manual">
                <ul className="faq-list">
                    {itemList.map((item) => {
                        const eventName = `${"常見問題_" + itemName.category_name}`;
                        const breadcrumbName = `${"常见问题 > " + itemName.category_name + " > " + item.detail_title}`;
                        return (
                            <li key={item.detail_id}>
                                <Link
                                    to={item.detail_link}
                                    onClick={() => {
                                        handleGtmClick({
                                            event: eventName,
                                            breadcrumb: breadcrumbName,
                                        });
                                    }}>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" className="arrow-icon" />
                                    <p>{item.detail_title}</p>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};
export default FaqListContent;
