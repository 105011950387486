const FaqCategoryList = [
    // 變數名稱findItem，10個大主題
    {
        category_id: 1,
        category_name: "创立活动",
        category_info: "活动创立操作，包括活动流程、活动设定、活动编辑",
        category_link: "create-campaign",
    },
    {
        category_id: 2,
        category_name: "层级问题",
        category_info: "会员层级问题，包括派发群组、派发门槛、黑名单",
        category_link: "hierarchy-issues",
    },
    {
        category_id: 3,
        category_name: "活动点数",
        category_info: "点数计算问题，包括累计点数、点数计算、点数共享",
        category_link: "campaign-points",
    },
    {
        category_id: 4,
        category_name: "活动预览 / 连结",
        category_info: "活动预览/连结问题，包括活动预览、活动连结、推广文案",
        category_link: "campaign-preview-link",
    },
    {
        category_id: 5,
        category_name: "活动时间",
        category_info: "活动时间问题，包括设定结束时间、延长领奖、停止活动",
        category_link: "campaign-duration",
    },
    {
        category_id: 6,
        category_name: "活动图包",
        category_info: "图包更换上传问题，包括更换图包、图包上传/下载、更换LOGO",
        category_link: "campaign-images-package",
    },
    {
        category_id: 7,
        category_name: "奖金 / 领奖设定",
        category_info: "活动奖金设定与领奖问题，包括奖金设定、奖金预算、领奖次数、中奖名单、未中奖",
        category_link: "bonus-prize-settings",
    },
    {
        category_id: 8,
        category_name: "活动报表",
        category_info: "查看活动报表，包括报表资讯、成效分析",
        category_link: "campaign-reports",
    },
    {
        category_id: 9,
        category_name: "活动推广",
        category_info: "活动显示位置，包括活动入口",
        category_link: "campaign-promotion",
    },
    {
        category_id: 10,
        category_name: "其他",
        category_info: "包括出款设定、装置问题…等",
        category_link: "others",
    },
];

export default FaqCategoryList;
