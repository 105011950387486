import "./index.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {useContext} from "react";
import {StoreContext} from "../../pages/StoreContext";

// import FaqCategoryList from "../../assets/data/FaqCategoryList";
import FaqDetailList from "../../assets/data/FaqDetailList";

function FaqSideBar({category_id, category_name, category_link}) {
    const {
        // 側邊欄
        selectedCategoryId,
        handleToggle,
        // gtm
        useGtmSender,
    } = useContext(StoreContext);
    const itemList = FaqDetailList.filter((item) => item.category_id === category_id); //小標

    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    return (
        <li key={category_id}>
            <NavLink
                to={`/faq/${category_link}`}
                onClick={() => {
                    handleToggle(category_id);
                }}>
                {category_name}
            </NavLink>

            {/* 次選單 */}
            {selectedCategoryId === category_id && (
                <ul className="faq-second-list">
                    {itemList.map((item) => {
                        const {detail_id, detail_title, detail_link} = item;
                        return (
                            <li key={detail_id}>
                                <NavLink
                                    to={`/faq/${category_link}/${detail_link}`}
                                    onClick={() => {
                                        handleGtmClick({
                                            event: "常見問題_側邊欄",
                                            breadcrumb: `常見問題 > ${category_name} > ${detail_title}`,
                                        });
                                    }}>
                                    <span className="arrow-icon">
                                        <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                                    </span>
                                    <span>{detail_title}</span>
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            )}
        </li>
    );
}

export default FaqSideBar;
