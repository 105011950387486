import "./index.scss";
import {Link, useLocation} from "react-router-dom";
import FaqCategoryList from "../../assets/data/FaqCategoryList";
import {useContext, useEffect, useState} from "react";
import {StoreContext} from "../../pages/StoreContext";

function FaqGroup() {
    const {pathname} = useLocation();
    const {useGtmSender, handleToggle} = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    let event = "首頁_常見問題";
    let breadcrumb = "首頁";
    if (pathname === "/faq") {
        event = "常見問題";
        breadcrumb = "常見問題";
    }
    return (
        <div className="faq-wrap">
            <h3>常见问题</h3>
            <div className="faq-content">
                <ul className="wrap">
                    {FaqCategoryList.map((item, id) => {
                        const {category_id, category_name, category_info, category_link} = item;
                        return (
                            <li key={category_id}>
                                <Link
                                    to={`/faq/${category_link}`}
                                    onClick={() => {
                                        handleToggle(category_id);
                                        handleGtmClick({
                                            event,
                                            breadcrumb,
                                            memo: category_name,
                                        });
                                    }}>
                                    <p className="faq-item-title">{category_name}</p>
                                    <p>{category_info}</p>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}

export default FaqGroup;
