import "./index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {useContext, useRef, useEffect} from "react";
import FaqDetailList from "../../assets/data/FaqDetailList";
import {StoreContext} from "../../pages/StoreContext";

// import FaqCategoryList from "../../assets/data/FaqCategoryList";

function FaqSearchBar() {
    const {
        // 側邊欄
        selectedCategoryId,
        setSelectedCategoryId,
        handleToggle,
        // search
        searchTerm,
        setSearchTerm,
        searchTitle,
        setSearchTitle,
        // searchResults,
        setSearchResults,
        // searching,
        setSearching,
        // gtm
        useGtmSender,
    } = useContext(StoreContext);

    const {pathname} = useLocation(); //抓網址使用

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    const navigate = useNavigate();
    const handleSearch = (event) => {
        event.preventDefault();
        const searchValue = event.target.elements.search.value;
        const searchTitle = event.target.elements.search.value;
        if (!searchValue) {
            setSearching(false);
            return;
        }
        if (!searchTitle) {
            setSearchTitle(false);
            return;
        }

        // 把input值放上去，不要消失
        setSearchTerm(searchValue);
        // setSearchTerm(""); //清空input
        // 把input值印給search頁的title
        setSearchTitle(searchTitle);
        // searchInputRef.current.value = searchValue;

        const results = FaqDetailList.filter((item) =>
            item.detail_title.toLowerCase().includes(searchValue.toLowerCase())
        );

        setSearchResults(results); //results

        setSearching(true);
        navigate("/faq/search");
    };

    // const {pathname} = useLocation();
    useEffect(() => {
        // 在這裡處理頁面變動時的邏輯
        if (pathname !== "/faq/search") {
            setSearchTerm(""); // 只有當不在 /search 頁面時才將 searchTerm 設置為空字串
        }
    }, [pathname, setSearchTerm]); // 監聽 pathname,setSearchTerm 變化

    return (
        <div className="search-wrap">
            <h3>您是否需要任何协助呢？</h3>
            <form className="search-box" onSubmit={handleSearch}>
                <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className="search-icon" />
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="请输入关键字"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    // defaultValue={searchTerm}
                    // key={searchTerm}
                />
                <button
                    type="submit"
                    onClick={() => {
                        handleGtmClick({
                            event: "常見問題_搜尋按鈕",
                            breadcrumb: "常見問題",
                            memo: searchTerm,
                        });
                    }}>
                    搜尋
                </button>
            </form>

            <span>热门问题</span>
            <ul>
                <li>
                    <Link
                        to="/faq/create-campaign"
                        onClick={() => {
                            handleToggle(1); //1
                            handleGtmClick({
                                event: "常見問題_熱門問題",
                                breadcrumbs: "常見問題",
                                memo: "創立活動",
                            });
                        }}>
                        创立活动
                    </Link>
                </li>
                <li>
                    <Link
                        to="/faq/campaign-preview-link"
                        onClick={() => {
                            handleToggle(4); //4
                            handleGtmClick({
                                event: "常見問題_熱門問題",
                                breadcrumbs: "常見問題",
                                memo: "活動連結",
                            });
                        }}>
                        活动连结
                    </Link>
                </li>
                <li>
                    <Link
                        to="/faq/campaign-duration"
                        onClick={() => {
                            handleToggle(5); //5
                            handleGtmClick({
                                event: "常見問題_熱門問題",
                                breadcrumbs: "常見問題",
                                memo: "延長領獎",
                            });
                        }}>
                        延长领奖
                    </Link>
                </li>
                <li>
                    <Link
                        to="/faq/hierarchy-issues"
                        onClick={() => {
                            handleToggle(2);
                            handleGtmClick({
                                event: "常見問題_熱門問題",
                                breadcrumbs: "常見問題",
                                memo: "門檻設定",
                            });
                        }}>
                        门槛设定
                    </Link>
                </li>
                <li>
                    <Link
                        to="/faq/campaign-images-package"
                        onClick={() => {
                            handleToggle(6);
                            handleGtmClick({
                                event: "常見問題_熱門問題",
                                breadcrumbs: "常見問題",
                                memo: "圖包下載",
                            });
                        }}>
                        图包下载
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export default FaqSearchBar;
