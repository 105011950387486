// AppRoutes.js
import React from "react";
import {createHashRouter} from "react-router-dom";

// pages
import {Template, Faq, NotFound, FaqIndex} from "./pages";
import {TemplateDetailContent} from "./pages/Template/TemplateDetailContent";

// components
import {PageContainer, PageContent} from "./components";

// 子頁面
import FaqListLayout from "./pages/Faq/FaqListLayout";
import FaqListContent from "./pages/Faq/FaqListContent";
import FaqListSearch from "./pages/Faq/FaqListSearch";

// template route data
import TemplateItemsData from "./assets/data/TemplateItemsData";
// faq route data
import FaqDetailList from "./assets/data/FaqDetailList";

const AppRoutes = createHashRouter([
    {
        path: "/",
        element: <PageContainer />, //全域變數塞在這兒，他以內的組件才能抓到全域變數，此層抓不到，新增頁面都要新增在此
        children: [
            {
                index: true, // 首頁設定，Home在這兒
                element: <PageContent />,
            },
            {
                path: "template",
                element: <Template />,
            },
            ...TemplateItemsData.map((page, template_id) => ({
                path: `template/${page.template_link}/:tabName`,
                default: {tabName: "intro"}, // tabName預設值為 'intro'
                element: <TemplateDetailContent templateTab2={page.template_tab2} />, //templateTab2是tab2 content的東西
                key: template_id,
            })),
            {
                path: "faq",
                element: <Faq />, //faq需要的變數在這兒
                children: [
                    {
                        index: true,
                        element: <FaqIndex />, //faq首頁
                    },
                    {
                        path: "search",
                        element: <FaqListSearch />,
                    },
                    {
                        path: ":faqPathName", //:faqPathName 必須是大標連結名稱
                        element: <FaqListLayout />, //架構頁
                        children: [
                            // 列表頁使用
                            {
                                index: true,
                                element: <FaqListContent />,
                            },
                            // 詳細頁
                            ...FaqDetailList.map((page, detail_id) => ({
                                path: page.detail_link,
                                element: <page.detail_element />,
                                key: detail_id,
                            })),
                        ],
                    },
                ],
            },
            {
                path: "*",
                element: <NotFound />,
            },
            {
                path: "not-found",
                element: <NotFound />,
            },
        ],
    },
]);

export default AppRoutes;
