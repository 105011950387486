const ServiceTab = ({
  serviceTabs,
  serviceCurrentItem,
  setServiceCurrentItem,
}) => {
  return (
    <div className="title-wrap">
      {serviceTabs.map((serviceTab, index) => {
        return (
          <div
            key={serviceTab.id}
            className={index === serviceCurrentItem ? "title active" : "title"}
            onClick={() => setServiceCurrentItem(index)}
          >
            <span>{serviceTab.decorationNumber}</span>
            {serviceTab.title}
          </div>
        );
      })}
    </div>
  );
};

export default ServiceTab;
