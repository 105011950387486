import "./index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState, useContext} from "react";
import {Link} from "react-router-dom";
import {StoreContext} from "../../pages/StoreContext";

// SliderData 輪播圖資料
import SliderData from "../../assets/data/SliderData";

// slick 套件
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style, display: "block"}} onClick={onClick}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style, display: "block"}} onClick={onClick}>
            <FontAwesomeIcon icon="fa-solid fa-chevron-left" />
        </div>
    );
}

const HomeSlider = () => {
    // 處理faq側邊欄active狀態的function
    const {handleToggle} = useContext(StoreContext);

    const {useGtmSender} = useContext(StoreContext);

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const [people, setPeople] = useState(SliderData);

    // 設定 Slider 的設定
    const sliderSettingsSmall = {
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        dots: true,
        infinite: true,
    };
    const sliderSettingsLarge = {
        slidesToShow: 1,
        swipeToSlide: false,
        focusOnSelect: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <div className="first-slider-wrap">
            <div className="first-slider-item-s">
                <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} {...sliderSettingsSmall}>
                    {people.map((person) => {
                        const {
                            slider_id,
                            slider_img,
                            slider_link,
                            slider_template_title,
                            slider_template_element,
                            slider_template_category_name,
                            // for faq
                            category_id,
                        } = person;
                        return (
                            <div key={slider_id} className="first-slider-item-S">
                                <Link
                                    to={slider_link}
                                    onClick={() => {
                                        handleToggle(category_id);
                                        handleGtmClick({
                                            event: "首頁_橫幅廣告",
                                            breadcrumb: "首頁",
                                            model: `${slider_template_element}`,
                                            model_name: `${slider_template_title}`,
                                            model_category: `${slider_template_category_name}`,
                                        });
                                    }}>
                                    <img src={slider_img} alt="" />
                                </Link>
                            </div>
                        );
                    })}
                </Slider>
            </div>

            {/* 背景大圖 */}
            <Slider asNavFor={nav1} ref={(slider2) => setNav2(slider2)} {...sliderSettingsLarge}>
                {people.map((person) => {
                    const {slider_id, slider_img} = person;
                    return (
                        <div key={slider_id} className="first-slider-item-l" style={{background: "#000"}}>
                            <img src={slider_img} alt="" />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default HomeSlider;
