import "./index.scss";

// import {useState} from "react";

const LightWindow = ({overlay, setOverlay, templateLink}) => {
    return (
        <>
            {overlay && (
                <>
                    <div
                        className="overlay"
                        onClick={() => {
                            setOverlay(false);
                        }}></div>

                    <div className="template-lightbox-wrap">
                        <img src={`../images/template/${templateLink}/promotion_img.jpg`} alt="" />
                    </div>
                </>
            )}
        </>
    );
};

export default LightWindow;
