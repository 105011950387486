import "./index.scss";

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export const NotFound = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(countdownInterval);
    }, []);

    useEffect(() => {
        if (countdown === 0) {
            // 在倒數結束後返回首頁
            navigate("/");
        }
    }, [countdown, navigate]);

    return (
        <div className="page-404">
            <h3>Oops! 找不到该页面</h3>
            <p>请尝试重新输入网址</p>
            <p>系统将于 {countdown} 秒后自动转跳至首页</p>
        </div>
    );
};
