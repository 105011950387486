import "./index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {StoreContext} from "../StoreContext";

// data
import FaqCategoryList from "../../assets/data/FaqCategoryList";

// components
import FaqSearchBar from "../../components/FaqSearchBar";
import FaqSideBar from "../../components/FaqSideBar";

const FaqListSearch = () => {
    const {
        // 側邊欄參數
        handleToggle,
        // search
        searchTerm,
        setSearchTerm,
        searchTitle,
        setSearchTitle,
        searchResults,
    } = useContext(StoreContext);

    return (
        <>
            <div className="wrap">
                <div className="faq-detail-wrap">
                    {/* 搜尋欄位 */}
                    <FaqSearchBar />
                    {/* 列表內容 */}
                    <div className="faq-detail">
                        <div className="faq-breadcrumb">
                            <Link to="/faq">常见问题</Link>
                            <FontAwesomeIcon icon="fa-solid fa-angle-right" className="arrow-icon" />

                            {/* 第2個麵包屑 */}
                            <span>搜尋結果</span>
                        </div>

                        <h3>{searchTitle}</h3>

                        <div className="faq-manual">
                            <ul className="faq-list">
                                {/* 判斷 searchResults是否有值，沒有值就返回未找到結果 */}
                                {searchResults && searchResults.length > 0 ? (
                                    <>
                                        {searchResults.map((item, index) => {
                                            const {category_id, category_link, detail_link} = item;
                                            return (
                                                <li key={index}>
                                                    <Link
                                                        to={`/faq/${category_link}/${detail_link}`}
                                                        onClick={() => {
                                                            handleToggle(category_id);
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon="fa-solid fa-angle-right"
                                                            className="arrow-icon"
                                                        />
                                                        <p>{item.detail_title}</p>
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <p>未找到相應的結果</p>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* 側邊欄 */}
                <div className="faq-sidebar-wrap">
                    <div className="faq-sidebar">
                        <p>
                            <Link to="/faq">常见问题</Link>
                        </p>
                        <ul className="faq-main-list">
                            {FaqCategoryList.map((item) => {
                                const {category_id, category_name, category_link} = item;
                                return (
                                    <FaqSideBar
                                        key={category_id}
                                        category_id={category_id} //category_id
                                        category_name={category_name}
                                        category_link={category_link}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqListSearch;
