// scss
import "./index.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// components
import FaqSearchBar from "../../components/FaqSearchBar";
import FaqSideBar from "../../components/FaqSideBar";

import {useState, useEffect} from "react";
import {Link, useParams, Outlet, useLocation, useNavigate} from "react-router-dom";

// 資料
import FaqCategoryList from "../../assets/data/FaqCategoryList";
import FaqDetailList from "../../assets/data/FaqDetailList";

const FaqListLayout = () => {
    const navigate = useNavigate(); //倒頁使用
    const {faqPathName} = useParams(); //抓路徑值使用，會去掉faq/之後的值
    const {pathname} = useLocation(); //抓網址使用

    const [isVisible, setIsVisible] = useState(false); //第3個麵包屑預設開關
    const [TheThirdBreadcrumbPathName, setTheThirdBreadcrumbPathName] = useState(""); //第3個麵包屑的文字

    // 處理404
    let checkNotFound = true; //倒頁開關
    let findItem = FaqCategoryList.find((item) => item.category_link === faqPathName);

    if (!findItem) {
        // 當findItem是undefined時，幫我塞空物件，並且開啟倒頁開關
        findItem = {};
        checkNotFound = false;
    }

    useEffect(() => {
        // 不符合條件就倒頁;
        if (!checkNotFound) {
            navigate("/not-found");
        }
    }, [faqPathName, pathname, checkNotFound, navigate]); //faqPathName

    // 判斷第3個麵包屑要不要打開
    useEffect(() => {
        const pathArray = pathname.split("/");
        const lastSegment = pathArray.pop(); //返回陣列最後一個值
        const foundItem = FaqDetailList.find((item) => item.detail_link === lastSegment);
        if (foundItem) {
            setIsVisible(true); //打開3的麵包屑
            setTheThirdBreadcrumbPathName(foundItem.detail_title); //把文字給麵包屑
        } else {
            setIsVisible(false);
        }
    }, [pathname, setIsVisible, setTheThirdBreadcrumbPathName]); //findItem, navigate

    return (
        <>
            <div className="wrap">
                <div className="faq-detail-wrap">
                    {/* 搜尋欄位 */}
                    <FaqSearchBar />
                    {/* 列表內容 */}
                    <div className="faq-detail">
                        <div className="faq-breadcrumb">
                            <Link to="/faq">常见问题</Link>
                            <FontAwesomeIcon icon="fa-solid fa-angle-right" className="arrow-icon" />

                            {/* 麵包屑 */}
                            {findItem && <Link to={`/faq/${findItem.category_link}`}>{findItem.category_name}</Link>}

                            {isVisible && (
                                <span>
                                    <FontAwesomeIcon icon="fa-solid fa-angle-right" className="arrow-icon" />
                                    <span>{TheThirdBreadcrumbPathName}</span>
                                </span>
                            )}
                        </div>
                        <h3>{isVisible ? TheThirdBreadcrumbPathName : findItem.category_name}</h3>
                        <Outlet />
                    </div>
                </div>
                {/* 側邊欄 */}
                <div className="faq-sidebar-wrap">
                    <div className="faq-sidebar">
                        <p>
                            <Link to="/faq">常见问题</Link>
                        </p>
                        <ul className="faq-main-list">
                            {FaqCategoryList.map((item) => {
                                const {category_id, category_name, category_link} = item;
                                return (
                                    <FaqSideBar
                                        key={category_id}
                                        category_id={category_id}
                                        category_name={category_name}
                                        category_link={category_link}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqListLayout;
