import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState, useContext} from "react";
import {StoreContext} from "../../StoreContext";
import {useLocation} from "react-router-dom";

// 1 ---------------------------------------------------------------------------------
// 1-1
export const HowToCreateCampaign = () => {
    return (
        <div className="faq-detail-info">
            <p>
                BBIN推出的全新功能『 <span>营运红包</span>
                』，一系列强大功能，让您举办活动时更『省心』、『省力』、『省时』。
                <br />
                营运红包支持：
            </p>
            <ul className="normal">
                <li>
                    <span className="num">1</span>支援层级、黑名单
                </li>
                <li>
                    <span className="num">2</span>支援跨游戏平台
                </li>
                <li>
                    <span className="num">3</span>随办随停止，活动时间更灵活！
                </li>
                <li>
                    <span className="num">4</span>条件门槛多元化，支持设立存款、打码条件
                </li>
                <li>
                    <span className="num">5</span>即时追踪报表，预算全方位有效控制
                </li>
                <li>
                    <span className="num">6</span>多种模版任您挑选，定时新增实用模版
                </li>
            </ul>

            <p>
                <span className="bold20">创立活动：</span>
                管端路径「<span>优惠中心＞抽红包优惠＞营运红包＞选择模版＞设定活动相关条件＞送出</span>」
            </p>
            <p className="step">
                <span>STEP1. 新增活动</span>
            </p>
            <p>
                <img src={"../../images/faq/01-01-01.jpg"} alt="" className="full" />
            </p>
            <p className="step">
                <span>STEP2. 营运红包</span>
            </p>
            <p>
                <img src={"../../images/faq/01-01-02.jpg"} alt="" className="full" />
            </p>
            <p className="step">
                <span>STEP3. 活动模版 ( 选择模版 ) ＞ 设定活动相关条件</span>
                <img src={"../../images/faq/01-01-03.jpg"} alt="" className="full" />
            </p>
        </div>
    );
};
// 1-2
export const CampaignProcess = () => {
    const {useGtmSender} = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };

    const [toggles, setToggles] = useState(Array(14).fill(false));
    const handleToggle = (index) => {
        const newToggles = [...toggles];
        newToggles[index] = !newToggles[index];
        setToggles(newToggles);
    };
    const eventName = "常見問題_创立活动_內文點擊展開按鈕";
    const eventBreadcrumb = "常见问题 > 创立活动 > 创立活动流程";

    const eventName2 = "常見問題_创立活动_內文點擊外開連結";

    return (
        <div className="faq-detail-info">
            <p className="step">
                <span>活动设定</span>
            </p>
            <p>
                <img src={"../../images/faq/01-02-01.jpg"} alt="" className="full" />
            </p>
            <ul className="two-colum">
                <li>
                    <span className="num2">1</span>
                    <p>
                        <span className="bold">活动模版 ：</span>
                        默认模版为『紅包雨』，建立<span>完成前</span>可自由选择模版，建立<span>完成後不可变更</span>模版
                    </p>
                </li>
                <li>
                    <span className="num2">2</span>
                    <p>
                        <span className="bold">活动名称 ：</span>
                        自行建立的活动名称，最多可输入50字
                    </p>
                </li>
                <li>
                    <span className="num2">3</span>
                    <p>
                        <span className="bold">活动日期： ：</span>
                        活动举办的区间
                        <br />
                        <span className="small">◆ 活动开始时间必须填写，结束时间可设置留白（无期限）</span>
                    </p>
                </li>
                <li>
                    <span className="num2">4</span>
                    <p>
                        <span className="bold">活动页时间显示 ：</span>
                        于活动页显示活动时间，点选『否』将不显示于活动页
                        <img src={"../../images/faq/01-02-02.png"} alt="" style={{marginTop: "10px"}} />
                    </p>
                </li>
                {/* toggle 0&1*/}
                <li>
                    <span className="num2">5</span>
                    <p>
                        <span className="bold">累计条件： </span>
                        累计活动点数可指定<span>依活动周期</span>或<span>每日重置</span>
                    </p>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[0] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(0);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "依活动周期",
                                });
                            }}>
                            依活动周期
                        </div>
                        {toggles[0] && (
                            <div className="faq-toggle-content">
                                会员于活动时间内可持续累计点数并同时领奖，每次开奖则依照各门槛设置的额度扣除累计点数。
                            </div>
                        )}
                    </div>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[1] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(1);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "每日重置",
                                });
                            }}>
                            每日重置
                        </div>
                        {toggles[1] && (
                            <div className="faq-toggle-content">
                                会员的累计点数于美东时间每日 00:00:00 重新计算，未领奖的点数将作废。
                            </div>
                        )}
                    </div>
                </li>
                {/* toggle 2*/}
                <li>
                    <span className="num2">6</span>
                    <p>
                        <span className="bold">抽红包期限 ：</span>
                        活动累计时间结束后，可延长领奖的时间
                    </p>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[2] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(2);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "抽红包期限_以下设定将无此栏位",
                                });
                            }}>
                            以下设定将无此栏位
                        </div>
                        {toggles[2] && (
                            <div className="faq-toggle-content">
                                ① 设定活动时无填写结束时间
                                <br />
                                ② 若有填写活动结束时间，但累计条件栏位选择「每日重置」
                                <br />③ 活动前无填写结束时间，活动中调整结束时间
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <span className="num2">7</span>
                    <p>
                        <span className="bold">活动标签 ：</span>
                        可自行建立活动标签，并针对活动进行分类
                    </p>
                </li>
                <li>
                    <span className="num2">8</span>
                    <p>
                        <span className="bold">同ip限领次数同ip限领次数 ：</span>
                        限制使用相同IP登入的会员账号数
                        <br />
                        <span className="small">◆ 同一会员帐号，领取次数将不在此限制</span>
                    </p>
                </li>
                <li>
                    <span className="num2">9</span>
                    <p>
                        <span className="bold">同姓名限领次数 ：</span>
                        限制相同真实姓名的会员账号数
                        <br />
                        <span className="small">◆ 同一会员帐号，领取次数将不在此限制</span>
                    </p>
                </li>
                <li>
                    <span className="num2">10</span>
                    <p>
                        <span className="bold">稽核设定 ：</span>
                        设定会员领取活动奖金后，是否需通过稽核点，才可出款
                    </p>
                </li>
                <li>
                    <span className="num2">11</span>
                    <p>
                        <span className="bold">写入退佣费用 ：</span>
                        设定活动奖金是否计算至代理退佣费用
                    </p>
                </li>
                {/* toggle 3*/}
                <li>
                    <span className="num2">12</span>
                    <p>
                        <span className="bold">开放领奖装置 ：</span>
                        设定活动仅能使用指定浏览器及装置可领取奖金
                        <br />
                        <span className="small">
                            ◆ 至少需勾选一个，系统预设全勾
                            <br />◆ 若有勾选APP，APP不受浏览器限制
                        </span>
                    </p>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[3] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(3);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "乖乖助手",
                                });
                            }}>
                            乖乖助手
                        </div>
                        {toggles[3] && (
                            <div className="faq-toggle-content">
                                减少会员 IP 遇到狹持的狀況，让会员能够安全的造访营运网。
                            </div>
                        )}
                    </div>
                </li>
            </ul>
            <p className="step">
                <span>活动条件</span>
                <img src={"../../images/faq/01-02-03.jpg"} alt="" className="full" />
            </p>
            <ul className="two-colum">
                {/* toggle 4+5*/}
                <li>
                    <span className="num2">13</span>
                    <p>
                        <span className="bold">派发对象 ：</span>
                        选择可参予活动的『会员层级』、『用户层级』
                    </p>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[4] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(4);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "会员层级",
                                });
                            }}>
                            会员层级
                        </div>
                        {toggles[4] && (
                            <div className="faq-toggle-content">
                                指定特定会员层级可进行活动
                                <br />
                                <a
                                    href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/account_managment/level_manage/transfer_level_manage/index.html"
                                    className="link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        handleGtmClick({
                                            event: eventName2,
                                            breadcrumb: eventBreadcrumb,
                                            button_text: "层级管理",
                                        });
                                    }}>
                                    <span>层级管理</span>
                                    <span className="arrow">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[5] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(5);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "用户等级",
                                });
                            }}>
                            用户等级
                        </div>
                        {toggles[5] && (
                            <div className="faq-toggle-content">
                                指定特定「终身等级」或「短期等级」用户等级可进行活动
                                <br />
                                <a
                                    href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/account_managment/level_manage/users_vip_level/index.html"
                                    className="link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        handleGtmClick({
                                            event: eventName2,
                                            breadcrumb: eventBreadcrumb,
                                            memo: "用户等级",
                                        });
                                    }}>
                                    <span>用户等级</span>
                                    <span className="arrow">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <span className="num2">14</span>
                    <p>
                        <span className="bold">排除黑名单 ： </span>
                        针对活动对象，可选择是否排除黑名单会员
                        <br />
                        <a
                            href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/promotion-commision/bonus_center/blacklist_management/index.html"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                handleGtmClick({
                                    event: eventName2,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "会员黑名单",
                                });
                            }}>
                            <span>会员黑名单</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </a>
                    </p>
                </li>
                <li>
                    <span className="num2">15</span>
                    <p>
                        <span className="bold">中奖名单：</span>
                        会员端的中奖名单轮播
                        <br />
                    </p>
                    <ul className="small-list">
                        <li>不显示：会员端不顯示中獎名單</li>
                        <li>实际名单：显示会员所属派发群组的近50笔实际名单</li>
                        <li>虚拟名单+实际名单：显示会员所属派发群组的近50笔 实际+虚拟名单</li>
                        <li>「上传虚拟名单」功能：仅能在勾选「虚拟名单+实际 名单」时显示</li>
                        <li>会员中奖0，将不显示于名单</li>
                        <li>虚拟名单中领奖金额可设定小数后两位</li>
                    </ul>
                </li>
                <li>
                    <span className="num2">16</span>
                    <p>
                        <span className="bold">指定存款类别 ：</span>
                        选择活动累计的指定存款方式
                    </p>
                </li>
                {/* toggle 6&7*/}
                <li>
                    <span className="num2">17</span>
                    <p>
                        <span className="bold">指定产品项目：</span>
                        可选择『选择平台产品』或『指定游戏群组』计算有效投注
                    </p>
                    {/* 6 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[6] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(6);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "选择平台产品",
                                });
                            }}>
                            选择平台产品
                        </div>
                        {toggles[6] && <div className="faq-toggle-content">针对游戏平台选择</div>}
                    </div>
                    {/* 7 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[7] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(7);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "指定游戏群组",
                                });
                            }}>
                            指定游戏群组
                        </div>
                        {toggles[7] && (
                            <div className="faq-toggle-content">
                                针对游戏指定分项选择，需先至『优惠安全管理 - 指定游戏群组』建立设定群组
                                <br />
                                <a
                                    href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/promotion-commision/bonus_center/blacklist_management/index.html"
                                    className="link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        handleGtmClick({
                                            event: eventName2,
                                            breadcrumb: eventBreadcrumb,
                                            button_text: "优惠安全管理-指定遊戲群組",
                                        });
                                    }}>
                                    <span>优惠安全管理 - 指定游戏群组</span>
                                    <span className="arrow">
                                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <span className="num2">18</span>
                    <p>
                        <span className="bold">申请次数 ：</span>
                        设定会员于活动间内的领奖次数或每日的领奖次数
                    </p>
                </li>
                <li>
                    <span className="num2">19</span>
                    <p>
                        <span className="bold">派发条件 ：</span>
                        可设定活动门槛条件为『累计存款』及『累计打码』
                    </p>
                    <ul className="small-list">
                        <li>同一会员帐号，领取次数将不在此限制</li>
                    </ul>
                </li>
                <li>
                    <span className="num2">20</span>
                    <p>
                        <span className="bold">派发群组 ：</span>
                        该活动的总预算或每日预算
                    </p>
                </li>
                {/* toggle *-10 */}
                <li>
                    <span className="num2">21</span>
                    <p>
                        <span className="bold">派发已达上限 ：</span>
                        当总预算或每日预算已达标时，系统根据勾选选项判断但后续会员欲抽奖状态
                    </p>
                    <br />
                    {/* 8 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[8] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(8);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "派发已达上限_举例说明",
                                });
                            }}>
                            举例说明：
                        </div>
                        {toggles[8] && (
                            <div className="faq-toggle-content">
                                奖项项次1：2～10，奖项项次2：11～10，总预算 剩余5会员抽中项次1，中奖金额5，派发中奖奖金
                                5，总预算剩余0;
                                <span>后续任一会员再进行抽奖视同未中奖或无法抽红包</span>
                            </div>
                        )}
                    </div>
                    {/* 9 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[9] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(9);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "视同未中奖",
                                });
                            }}>
                            视同未中奖
                        </div>
                        {toggles[9] && (
                            <div className="faq-toggle-content">
                                ① 会员点数及领奖次数仍会扣除门檻条件进行抽奖，但 结果皆为『未中奖』 <br />②
                                活动说明仅显示最高假奖金
                            </div>
                        )}
                    </div>
                    {/* 10 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[10] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(10);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "无法抽红包",
                                });
                            }}>
                            无法抽红包
                        </div>
                        {toggles[10] && (
                            <div className="faq-toggle-content">系统直接阻挡会员进行领奖，不扣除任何条件及次数</div>
                        )}
                    </div>
                    <p>
                        <span className="small">
                            ★ 总预算或每日预算 ＜ 会员中奖金额时，固定派发会员进行抽奖的该门槛「项次1」最小奖金
                        </span>
                        <br />
                        <span className="small">
                            例：
                            <br />
                            奖项项次1：2～10，奖项项次2：11～10，总预算剩余5
                            <br />
                            会员A抽中项次1，中奖金额6，中奖金额 ＞ 总预算，视同 <br />
                            派发最小奖金2
                            <br />
                            会员B抽中项次2，中奖金额11，中奖金额 ＞ 总预算，视 <br />
                            同派发最小奖金2
                        </span>
                    </p>
                </li>
            </ul>
            <p className="step">
                <img src={"../../images/faq/01-02-04.jpg"} alt="" className="full" />
            </p>
            <ul className="two-colum">
                <li>
                    <span className="num2">22</span>
                    <p>
                        <span className="bold">派发群组：</span>
                        新增及设定各群组派发的门槛条件 ( <span className="icon-plus">+</span> 为新增群组)
                        <br />
                    </p>
                    <ul className="small-list">
                        <li>依照派发对象模式设定对应的派发群组，最多可设定 10组</li>
                    </ul>
                </li>
                <li>
                    <span className="num2">23</span>
                    <p>
                        <span className="bold">派发群组名称 ：</span>
                        非必填，可设定派发群组的名称显示于活动说明页中
                        <br />
                    </p>
                    <ul className="small-list">
                        <li>会员端仅能查看到该会员所属组名及门槛条件</li>
                    </ul>
                    <p>
                        <img src={"../../images/faq/01-02-05.jpg"} alt="" />
                    </p>
                </li>
                <li>
                    <span className="num2">24</span>
                    <p>
                        <span className="bold">派发层级/等级 ：</span>
                        依派发对象的选项，设定「各层级」或「各等级」的派发群组
                        <br />
                        <a
                            href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/account_managment/level_manage/transfer_level_manage/index.html"
                            className="link"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                handleGtmClick({
                                    event: eventName2,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "派发层级/等级_层级管理",
                                });
                            }}>
                            <span>层级管理</span>
                            <span className="arrow">
                                <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                            </span>
                        </a>
                    </p>
                </li>
                <li>
                    <span className="num2">25</span>
                    <p>
                        <span className="bold">派发门槛 ：</span>
                        设定各门槛指定条件及派发奖金
                        <br />
                    </p>
                    <ul className="small-list">
                        <li>门槛最多可设定10组</li>
                        <li>最高门槛：若会员符合多门槛条件，会员仅能在符合的 最高门槛进行抽奖</li>
                        <li>任选门槛：若会员符合多门槛条件，可任意选择门槛进 行抽奖</li>
                    </ul>
                </li>
                <li>
                    <span className="num2">26</span>
                    <p>
                        <span className="bold">扣除条件 ：</span>
                        设定活动门槛存款及打码条件
                        <br />
                        <span className="small">
                            ◆ 累计存款达：会员需达到存款门槛条件才能抽奖
                            <br />◆ 累计打码达：会员需达到打码门槛条件才能抽奖
                        </span>
                    </p>
                </li>
                <li>
                    <span className="num2">27</span>
                    <p>
                        <span className="bold">假奖金 (固定/随机) ：</span>
                        显示于活动页最高奖金和说明页获得奖金
                        <br />
                    </p>
                    <ul className="small-list">
                        <li>固定：派发固定值 XX 元，说明页及说明页显示最高 XX 元</li>
                        <li>随机：派发区间最小值 XX~最大值XX 元，活动首页会 显示最高奖金</li>
                    </ul>
                    <p>
                        <span className="small">
                            (1)若最小值＝0，活动说明页显示最高奖金
                            <br />
                            (2)若最小值≠0，活动说明页区间奖金
                        </span>
                    </p>
                </li>
                <li>
                    <span className="num2">28</span>
                    <p>
                        <span className="bold">派发门槛条件 ：</span>
                        设定单一门槛奖项金额/机率/每日包数限制
                    </p>
                    {/* 11 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[11] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(11);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "奖项金额",
                                });
                            }}>
                            奖项金额
                        </div>
                        {toggles[11] && (
                            <div className="faq-toggle-content">
                                ① 假奖金选择「固定值」，奖项仅能填写固定值；假奖金选择「随机值」，奖项仅能填写随机值
                                <br />
                                ② 奖项的最小值可设定0
                                <br />
                                ③ 奖项金额设定0，会员抽中
                                <br />
                                会员端：开奖画面显示未中奖，领奖记录显示未中奖，往来记录显示「营运红包 交易额度0.00」
                                <br />管 端：该笔派奖显示于派奖记录详情中，可领金额显示「0.00」
                            </div>
                        )}
                    </div>
                    {/* 12 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[12] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(12);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "机率",
                                });
                            }}>
                            机率
                        </div>
                        {toggles[12] && (
                            <div className="faq-toggle-content">
                                可设定各奖金的派发机率
                                <br />
                                ① 奖项机率可设定小数后两位，总和加总需＝100％
                                <br />② 机率不支持设定0
                            </div>
                        )}
                    </div>
                    {/* 13 */}
                    <div className="faq-toggle-wrap">
                        <div
                            className={`faq-toggle-title ${toggles[13] ? "active" : ""}`}
                            onClick={() => {
                                handleToggle(13);
                                handleGtmClick({
                                    event: eventName,
                                    breadcrumb: eventBreadcrumb,
                                    button_text: "每日包数限制",
                                });
                            }}>
                            每日包数限制
                        </div>
                        {toggles[13] && (
                            <div className="faq-toggle-content">
                                设定各项奖金每日最多可派发包数
                                <br />
                                ① 每个派发门槛的「项次1」固定奖项包数为「不限制」
                                <br />② 需注意包数每日都会重置，且活动中无法调整奖项包数
                            </div>
                        )}
                    </div>
                </li>
                <li>
                    <span className="num2">29</span>
                    <p>
                        <span className="bold">新增奖项 ：</span>
                        可新增至最多10項獎金項次
                        <br />
                    </p>
                </li>
            </ul>
            <p className="bold" style={{textAlign: "center"}}>
                <span>设置完成后，请点选送出</span>
            </p>
        </div>
    );
};
// 1-3
export const ConfirmCampaignSuccess = () => {
    return (
        <div className="faq-detail-info">
            <p>
                您可前往管端「 <span>优惠中心＞抽红包优惠＞活动管理</span> 」查看活动是否建立成功
            </p>
            <p>快速搜寻：① 选择活动状态：未开始 ② 活动类型：营运红包</p>
        </div>
    );
};
// 1-4
export const EditOptionsDuringCampaign = () => {
    return (
        <div className="faq-detail-info">
            <ul className="circle-list">
                <li>活动进行中您可以编辑</li>
                <li>活动名称</li>
                <li>活动结束时间</li>
                <li>活动页时间显示</li>
                <li>抽红包期限</li>
                <li>活动标签</li>
                <li>稽核设定</li>
                <li>写入退佣费用</li>
                <li>开放领奖装置：网页 ( 电脑板、行动版网页版H5 ) 、下载版 ( APP版 )</li>
                <li>排除黑名单</li>
                <li>中奖名单</li>
                <li>上传虚拟名单</li>
                <li>指定存款类别</li>
                <li>指定产品项目：选择平台产品、指定游戏群组</li>
                <li>派发条件：累计存款、累计打码</li>
                <li>派发群组：总预算、每日预算</li>
                <li>派发已达上限：视同未中奖、无法抽红包</li>
                <li>派发群组名称</li>
                <li>派发层级/等级 ( 终身累计打码 )</li>
                <li>扣除条件：累计存款达 、累计打码达</li>
                <li>假奖金：固定、随机</li>
                <li>奖项金额、机率</li>
                <li>奖项金额</li>
            </ul>
        </div>
    );
};
// 1-5
export const AdjustBonusesDuringCampaign = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以的，<span>活动期间可任意调整奖金金额</span>，但要注意真奖金设定不能低于假奖金最低额。
            </p>
        </div>
    );
};
// 1-6
export const SetMultipleThresholdsCampaign = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以的，最多支持十个门槛。
                <br />
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                营运模组可支持最多『十个派发群组』、『十个派发门槛』。
            </p>
        </div>
    );
};
// 1-7
export const SetBBCasino = () => {
    return (
        <div className="faq-detail-info">
            <p>
                创立活动时，请于「 活动条件＞指定产品项目＞指定游戏群组＞新增游戏群组」
                <img src={"../../images/faq/01-07-01.jpg"} alt="" />
                <br />
                新增游戏群组＞指定产品：BB电子；指定分项：老虎机
                <img src={"../../images/faq/01-07-02.png"} alt="" />
                <br />
                活动页将显示您所选的分项(老虎机)所有的游戏了哦~
                <img src={"../../images/faq/01-07-03.jpg"} alt="" />
            </p>
        </div>
    );
};
// 2 done ---------------------------------------------------------------------------------
// 2-1
export const SupportHierarchyBlacklist = () => {
    const {useGtmSender} = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };
    const eventName = "常見問題_层级问题_內文點擊外開連結";
    const eventBreadcrumb = "常见问题 > 层级问题 > 这可以支持层级、黑名单吗？";
    return (
        <div className="faq-detail-info">
            <p>
                <span>可以</span>，此营运红包可以限定派发对象以及排除黑名单。 <br />
                派发对象：管端路径「 <span>活动条件＞派发对象 ( 层级/等级 )</span> 」<br />
                建立层级群组：管端路径「<span>账号管理＞层级管理＞新增层级＞输入名称/条件＞送出</span>」
            </p>
            <p>
                ☆ 新增层级群组请参考
                <a
                    href="https://bbin-tutorial-frontend.s3.ap-northeast-1.amazonaws.com/lang-cn/account_managment/member_group_management/index.html"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        handleGtmClick({
                            event: eventName,
                            breadcrumb: eventBreadcrumb,
                            button_text: "会员分群管理",
                        });
                    }}>
                    <span>会员分群管理</span>
                    <span className="arrow">
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                    </span>
                </a>
            </p>

            <p>
                排除黑名单：管端路径「<span>活动条件＞排除黑名单 ( 不排除/排除 )</span>」<br />
            </p>
            <p>
                ☆ 新增黑名单请参考
                <a
                    href="https://bbin-tutorial.s3.amazonaws.com/lang_cn/promotion-commision/bonus_center/blacklist_management/index.html"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        handleGtmClick({
                            event: eventName,
                            breadcrumb: eventBreadcrumb,
                            button_text: "会员黑名单",
                        });
                    }}>
                    <span>会员黑名单</span>
                    <span className="arrow">
                        <FontAwesomeIcon icon="fa-solid fa-chevron-right" />
                    </span>
                </a>
            </p>
            <p>
                <img src={"../../images/faq/02-01-01.jpg"} alt="" />
            </p>
        </div>
    );
};
// 2-2
export const ExplanationHierarchyGroupSettings = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>派发群组：</span>新增及设定个群组派发的门槛条件。
                <br />◆ 注意：同个层级/等级仅能设定在一个派发群组内
                <br />
                例如：
                <br />
                派发群组1：针对 未分层 会员设定存款1500、打码3000、赠送最高88的活动
                <br />
                派发群组2：针对 普通 会员设定存款10000、打码5000、赠送最高1688的活动
                <br />
                如何设定群组活动条件？
                <br />
                管端路径「<span>营运红包＞活动条件＞派发对象 ( 层级 )＞设定条件＞派发群组1＞送出</span>」
                <br />
                举例：红包雨针对<span>未分层、普通会员</span>做活动，总预算为150000元、每日预算为5000元。
                <img src={"../../images/faq/02-02-01.png"} alt="" className="full" />
            </p>
            <p className="step">
                <span>STEP1. 派发对象 ( 层级为例 )＞设定条件</span>
            </p>
            <p>
                <img src={"../../images/faq/02-02-02.jpg"} alt="" className="full" />
            </p>
            <p className="step">
                <span>STEP2-1. 派发群组1 ( 未分层会员 )＞门槛一</span>
            </p>
            <p>
                <img src={"../../images/faq/02-02-03.jpg"} alt="" className="full" />
            </p>
            <p className="step">
                <span>STEP2-2. 派发群组1 ( 未分层会员 )＞门槛二</span>
            </p>
            <p>
                <img src={"../../images/faq/02-02-04.jpg"} alt="" className="full" />
            </p>
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                如要继续为增加『派发群组』或『派发门槛』，请按项目旁的『
                <span className="icon-plus">+</span> 』键。 <br />
                *最多可新增十个派发群组、派发门槛
            </p>
            <p className="step">
                <span>STEP3-1. 派发群组2 ( LV2会员 )＞门槛一</span>
            </p>
            <p>
                <img src={"../../images/faq/02-02-05.jpg"} alt="" className="full" />
            </p>
            <p>
                如要继续为增加『派发群组』或『派发门槛』，请按项目旁的『 <span className="icon-plus">+</span> 』键。
                <br />
                *最多可新增十个派发群组、派发门槛
            </p>
        </div>
    );
};
// 2-3
export const ExplanationHierarchyDistributionThresholdSettings = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>派发门槛：</span>
                设定各门槛指定条件及派发金额 ( 最多十组门槛，开始后不可修改 )<br />
            </p>
            <ul className="small-list">
                <li>最高门槛：若会员符合多门槛条件，会员仅能在符合的最高门槛进行抽奖</li>
                <li>任选门槛：若会员符合多门槛条件，可任意选择门槛进行抽奖</li>
            </ul>
            <p>
                <span className="small">
                    <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                    最高门槛优先说明：系统将从最高门槛 ( 门槛四 ) 优先判断会员是否达标，并派发奖金。
                </span>
                <br />
                举例1. 会员累计存款为15000、累计打码35000，系统将会判对此会员可开第三门槛奖金。
                <img src={"../../images/faq/02-03-01.png"} alt="" />
            </p>
            <p>
                举例2. 会员累计存款为50000、累计打码10000，系统将会判对此会员可开第四门槛奖金。
                <br />
                <span className="small"> ★系统将优先判断是否符合门槛条件再派发金额 (非依奖金高低为主)。</span>
                <img src={"../../images/faq/02-03-02.png"} alt="" />
            </p>
            <p>
                <span className="small">
                    <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                    任选门槛说明：会员满足各项门槛需求后可任选门槛进行开奖。
                </span>
                <br />
                举例1. 会员累计存款为50000、累计打码10000，会员已满足四个门槛条件可任选门槛开奖。
                <img src={"../../images/faq/02-03-03.jpg"} alt="" />
            </p>
        </div>
    );
};
// 2-4
export const ExplanationHighestOptionalThresholds = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <img src={"../../images/faq/02-04-01.jpg"} alt="" />
            </p>
        </div>
    );
};

// 3 ---------------------------------------------------------------------------------
// 3-1
export const CalculateEventPoints = () => {
    return (
        <div className="faq-detail-info">
            <p>
                会员于活动期间进行指定存款及投注游戏
                <span>
                    <br />★ 存款1元，获得累积存款点数1点
                    <br />★ 有效投注1元，获得累积打码点数1点
                </span>
                <br />( 活动页于每 三十分钟 更新会员累计点数一次)
            </p>
            <p>
                累计点数条件设定
                <br />
                累计活动点数可指定依活动周期或每日重置
            </p>
            <ul className="small-list">
                <li>
                    依活动周期：会员于活动时间内可持续累计点数并同时领奖，每次开奖则依照各门槛设置的额度扣除累计点数
                </li>
                <li>每日重置：会员的累计点数于美东时间每日 00:00:00 重新计算，未领奖的点数将作废</li>
            </ul>
        </div>
    );
};
// 3-2
export const SharePointsMultipleEvents = () => {
    return (
        <div className="faq-detail-info">
            <p>
                如您设定的活动期间、指定产品、派发对象、派发条件有相同或部分重叠，活动点数将会同时累计。
                <br />
                举例：
                <br />
                活动A，活动时间： 01/01 ~ 01/15，活动产品：全电子馆
                <img src={"../../images/faq/03-02-01.jpg"} alt="" />
            </p>
            <p>
                活动B，活动时间 01/10 ~ 01/30 ，活动产品：BB电子馆
                <br />
                <img src={"../../images/faq/03-02-02.jpg"} alt="" />
                LV1会员于 01/01 存款500元、 01/10 存款1000元、 01/11 PG电子打码3000、 01/12 BB电子打码500。
                <br />
                该会员将得：
                <br />
                活动A，PG电子 + BB电子累计打码点数为3500点，符合门槛1开奖资格
                <br />
                活动B，01/10累计存款点数为1000点 ， BB子电累计打码点数为 500点，无法参加抽奖
                <br />
                (符合门槛1累积存款500，但未符合指定游戏1500，故该会员未达标！)
            </p>
        </div>
    );
};
// 3-3
export const AccumulatePointsUsingProfitLoss = () => {
    return (
        <div className="faq-detail-info">
            <p>
                为促使会员持续投注并提高货量，<span>目前仅支持使用「存款」、「有效打码」</span>来累计活动的点数，
                <br />
                累计点数条件暂不支持「损益」。
            </p>
        </div>
    );
};
// 3-4
export const DailyResetAccumulatedPoints = () => {
    return (
        <div className="faq-detail-info">
            <p>可以，营运红包可设定以下2种累计点数条件。</p>
            <p className="small">
                ◆ <span>依活动周期</span>：会员于活动时间内可持续累计点数，每一次开奖则依照各门坎设置的额度扣除累计点数
                <br />◆ <span>每日重置</span>：会员将于美东时间晚上 00:00:00 重新累计点数，未用完点数将作废。
            </p>
        </div>
    );
};
// 3-5
export const SetDailyResetLimitEventPoints = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以，营运红包的设定「累计条件」选择「每日重置」会员将于美东时间晚上11:59:59重新累计点数，
                <br />
                未用完点数将作废。
            </p>
        </div>
    );
};
// 4 ---------------------------------------------------------------------------------
// 4-1
export const FeaturePreviewOnline = () => {
    return (
        <div className="faq-detail-info">
            <p style={{marginBottom: "40px"}}>
                <span>【您反映！我们重视！】</span>BBIN 推出全新的活动网址复制和活动预告功能，灵活更新，无需等待。
                <br />
                <br />
                优惠中心 - 运营红包大幅升级
                <br />
                本次升级活动『未开始』前的网址复制、预告期显示等功能，让您举办活动更加轻松便捷。
            </p>
            <div className="clearfix">
                <p style={{width: "44%", float: "left", padding: "50px 40px 0 0"}}>
                    <span style={{fontWeight: "bold", color: "#fff"}}>① 推广预告期显示</span>
                    <br />
                    在您的活动尚未开始之前，您可以通过运营网、论坛、TG群组提前宣传新活动，吸引更多会员参与。
                </p>
                <p>
                    <img src={"../../images/faq/04-01-01.jpg"} alt="" />
                </p>
            </div>
            <div className="clearfix" style={{margin: "20px 0"}}>
                <p style={{width: "44%", float: "left"}}>
                    <img src={"../../images/faq/04-01-02.jpg"} alt="" />
                </p>
                <p style={{padding: "50px 40px 0 0"}}>
                    <span style={{fontWeight: "bold", color: "#fff"}}>② 活动网址复制</span>
                    <br />
                    一键复制活动网址，让您的推广更加便捷高效。
                </p>
            </div>
            <div className="clearfix">
                <p style={{width: "44%", float: "left", padding: "50px 60px 0 0"}}>
                    <span style={{fontWeight: "bold", color: "#fff"}}>③ 图包 / 奖金模型，随时更新随时显示</span>
                    <br />
                    无论是更换图包还是奖金模型，点击确认后，信息将即时显示，减少设置错误，提高办活动效率。
                </p>
                <p>
                    <img src={"../../images/faq/04-01-03.jpg"} alt="" />
                </p>
            </div>

            <p style={{color: "#e6211e", textAlign: "center", paddingTop: "20px"}}>
                立即体验全新功能将帮助您快速搭建活动，轻松创造高流量！
            </p>
        </div>
    );
};
// 4-2
export const FeaturePreviewIntro = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>活动网址复制功能</span>
                <br />
                管端「 优惠中心＞抽红包优惠＞活动管理 ＞操作：活动网址」
                <img src={"../../images/faq/04-02-01.jpg"} alt="" style={{margin: "10px 0"}} />
                点击『活动网址』复制网址（内容为『/activity/#/原始活动ID』，您需于前方串上营运网网址即可使用）
                <br />
                例如：https://www.bbin168.net/<span style={{color: "#E7211E"}}>activity/#/1668</span>
                <br />
                <span style={{color: "#fff", marginLeft: "170px"}}>您的营运网/activity/#/原始活动ID</span>
                <br />
                <span style={{color: "#E7211E"}}>活动结束后，将不再显示 活动网址复制按钮 以及 此活动网址也会失效</span>
            </p>
            <p>
                <span>活动预告功能</span>
                <br />
                管端「 优惠中心＞抽红包优惠＞<span style={{color: "#E7211E"}}>活动管理＞活动状态：全部 或 未开始</span>
                ＞操作：活动网址」
                <br />
                此活动预告仅为体验流程，奖金不会真实派发
                <br />
                <img src={"../../images/faq/04-02-02.png"} alt="" style={{margin: "10px 0"}} />
                活动未开始时，您可以通过预告页面进行以下操作：
                <br />
                ① 检视页面信息/图包
                <br />
                您可以检视活动页面的信息和相关上传图片。
                <img src={"../../images/faq/04-02-03.jpg"} alt="" style={{margin: "10px 0"}} />
                <br />② 奖金模型检查 - 以第一派发群组为主
                <br />
                您可以实时检视您的奖金设定内容，无需再创立测试活动。
                <br />
                此活动预告仅为体验流程，奖金不会真实派发。
                <br />
                <span>因此预告期只显示派发群组一之奖金模型内容，无法根据派发群组显示奖金模型。</span>
                <br />
                <span style={{color: "#E7211E"}}>请注意：</span>
                <span>因层级变更频繁，避免会员降级，与原本看到的领奖金额有落差，而造成客诉。 </span>
                <br />
                <span style={{display: "block", margin: "10px 0"}}>
                    例如：设定『未分层』、『VIP』两个层级，活动未开始前，VIP会员将会看到『未分层』群组奖金模型，
                    <br />
                    并非所属层级奖金。
                </span>
                <span style={{color: "#fff"}}>
                    此外，建议请业主将派发群组一的等级及层级皆为最低符合标准。 <br />
                    如举办多群组活动，请将最低层级排为群组一
                </span>
            </p>
            <p>
                <span style={{color: "#E7211E"}}>常见问题</span> <br />
                Q1：会员未登入能否看到活动预告
                <br />
                <span style={{display: "block", margin: "0 0 10px 0", color: "#fff"}}>
                    ANS：可以的，会员未登入下也是可以看到该活动預告頁的哦！
                    <br />
                    建议您可以于营运网、论坛、TG放上文宣连结曝光您的活动
                </span>
                Q2：黑名单会员能否看到活动预告 <br />
                <span style={{display: "block", margin: "0 0 10px 0", color: "#fff"}}>
                    ANS：会的，因预告页无法判断该会员层级，所以黑名单会员也是会看到该活动的呢！
                    <br />
                    但活动开始后，如您设置阻挡黑名单功能将不会显示于该会员页面。
                </span>
                Q3：为什么会员使用电脑版，提示弹窗还是会显示 本活动仅可以....：电脑版
                <span style={{display: "block", margin: "0 0 10px 0", color: "#fff"}}>
                    ANS：因提升会员体验参与度，预告页并无装置之分，皆可开奖。
                    <br />
                    故弹窗只是提醒会员活动正式开始后须于设定装置内领奖
                </span>
                Q4：为什么『进行中』活动编号与活动网址不一样?
                <span style={{display: "block", margin: "0 0 10px 0", color: "#fff"}}>
                    ANS：因为活动更新调整后活动编号会改变，但活动网址是抓取"最初"活动编号，故有可能两者不相同。
                </span>
                <img src={"../../images/faq/04-02-04.jpg"} alt="" style={{margin: "10px 0"}} />
            </p>
        </div>
    );
};
// 4-3
export const FeaturePreviewPromo = () => {
    return (
        <div className="faq-detail-info">
            <p>可以的，建立完成时您可以使用预告页查看您的图包、门槛、奖金，您也可以使用预告页网址对外推广哦。</p>
            <p>
                ★第一步：活动网址复制功能
                <br />
                管端「 优惠中心＞抽红包优惠＞活动管理 ＞操作：活动网址」 <br />
                您可以复制连结后，输入『营运网网址』
                <span style={{color: "#E7211E"}}>/activity/#/原始活动ID</span> 于浏览器查看。 <br />
                例如：https://www.bbin168.net<span style={{color: "#E7211E"}}>/activity/#/1668</span>
            </p>
            <p>
                ★ 第二步：您能于预告页查看图包、门槛、奖金
                <img src={"../../images/faq/04-03-01.png"} alt="" style={{margin: "10px 0"}} />
                请注意：预告期只显示派发群组一之奖金模型内容。
                <br />
                <span>※活动結束后系统将不会保留网址和活动页。</span>
            </p>
        </div>
    );
};

// 5 ---------------------------------------------------------------------------------
// 5-1
export const EventTimeBeijingOrEastern = () => {
    return (
        <div className="faq-detail-info">
            <p>
                管端营运红包显示的时间是<span>美东时间</span>，
                <br />
                每档活动所选的期间皆于美东时间 『 00:00:00 』开始 ~ 『 23:59:59 』结束。
            </p>
        </div>
    );
};
// 5-2
export const LongTermEventNoEndTime = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以，请在活动日期的结束时间留空即可。
                <br />
                （留空=无期限）
            </p>
        </div>
    );
};
// 5-3
export const ExtendedPrizeClaimSetting = () => {
    return (
        <div className="faq-detail-info">
            <p>「延長领奖」：延长领奖期间，将不再累积会员点数，仅能进行领奖动作。</p>
            <p className="small">
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                必须填写<span>活动开始</span>及<span>结束时间</span>
                并累计条件选择『依活动周期』，于『抽红包限制』栏位填入天数。
                <br />( 可設置最少 一天 ～ 最多三十一天 )
                <img src={"../../images/faq/05-03-01.jpg"} alt="" />
            </p>
            <p>
                请注意！
                <br />
                <span className="small">
                    ( 1 ) 如您的活动日期『无结束时间』或累计条件选择为 『每日重置』将无法设定延长领奖
                    <br /> ( 2 ) <span>活动关联的『设定图文』请切记预计显示时间的结束时间等于延长日。</span>
                </span>
                <br />
                举例：设定 2023/11/30 活动结束，延长领奖3天
                <br />
                那图文设定要记得设定结束时间是 2023/12/2
            </p>
            <p>
                <img src={"../../images/faq/05-03-02.jpg"} alt="" />
            </p>
            <span className="bottom-line">★提醒您！若有调整活动时间，请一并调整优惠图文。</span>
        </div>
    );
};
// 5-4
export const StopEventAnyTime = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>可以</span>，您可以随时停止活动。
                <br />
                活动停止活动方式：
                <br />
                <span className="num2">1</span>调整活动日期 ：填入结束时间
                <img src={"../../images/faq/05-04-01.jpg"} alt="" />
            </p>
            <p>
                <span className="num2">2</span>操作停止：停止后就不能再重启，本档活动会员所累积的点数也会作废无法再领奖
                <img src={"../../images/faq/05-04-02.jpg"} alt="" className="full" />
            </p>
        </div>
    );
};
// 5-5
export const ChangeEventTime = () => {
    return (
        <div className="faq-detail-info">
            <p>
                活动开始前：可任意调整活动开始及结束时间。
                <br />
                <span>活动进行中：仅能调整活动结束时间。</span>
            </p>
        </div>
    );
};
// 6 ---------------------------------------------------------------------------------
// 6-1
export const TipsChangingLogoImages = () => {
    const {useGtmSender} = useContext(StoreContext);
    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };
    const eventName = "常見問題_活动图包_更换LOGO、标题、背景、图包注意事项";
    const eventBreadcrumb = "常见问题 > 活动图包 > 更换LOGO、标题、背景、图包注意事项";
    return (
        <div className="faq-detail-info">
            <p>
                <span>
                    <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                    请您需先完成活动建立后，再依照以下步骤进行图包的更改哦
                </span>
            </p>
            <p className="title">【步骤一】依据需求下载图包后调整您想要的图片</p>
            <ul className="download" style={{margin: "0 0 20px"}}>
                <li>
                    <a
                        href="https://www.bbin-news.com/archives/红包雨 - 活动图包.zip"
                        download="红包雨 - 活动图包.zip"
                        onClick={() => {
                            handleGtmClick({
                                event: eventName,
                                breadcrumb: eventBreadcrumb,
                                memo: "重點圖包_下載",
                            });
                        }}>
                        <FontAwesomeIcon icon="fa-regular fa-folder-open" className="icon" />
                        <span>重点图包</span>
                        <i>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                        </i>
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.bbin-news.com/archives/红包雨 - 模版图包.zip"
                        download="红包雨 - 模版图包.zip"
                        onClick={() => {
                            handleGtmClick({
                                event: eventName,
                                breadcrumb: eventBreadcrumb,
                                memo: "活動頁全圖包_下載",
                            });
                        }}>
                        <FontAwesomeIcon icon="fa-regular fa-image" className="icon" />
                        <span>活动页全图包</span>
                        <i>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                        </i>
                    </a>
                </li>
                <li>
                    <a
                        href="https://www.bbin-news.com/archives/红包雨 - 广宣素材.zip"
                        download="红包雨 - 广宣素材.zip"
                        onClick={() => {
                            handleGtmClick({
                                event: eventName,
                                breadcrumb: eventBreadcrumb,
                                memo: "廣宣素材_下載",
                            });
                        }}>
                        <FontAwesomeIcon icon="fa-solid fa-bullhorn" className="icon" />
                        <span>广宣素材</span>
                        <i>
                            <FontAwesomeIcon icon="fa-solid fa-arrow-right" className="icon" />
                        </i>
                    </a>
                </li>
            </ul>
            <p>上方下载专区有提供两种图包给您参考应用，以下说明差异</p>
            <p className="step">
                <span className="num">1</span>
                <span>重点图包(附PSD)</span>
            </p>
            <p>共有以下5张图片，附有PSD编辑档方便您编辑使用</p>
            <p>
                <img src={"../../images/faq/06-01-02.png"} alt="" className="full" />
            </p>
            <p className="step">
                <span className="num">2</span>
                <span>活动页全图包</span>
            </p>
            <p>您下载后可自由替换图包内容，您可以依照您的活动需求设计出独一无二的模版活</p>
            <p>
                <img src={"../../images/faq/06-01-03.jpg"} alt="" className="full" />
            </p>
            <p>
                需注意：修改的图档<span>文件名、格式、尺寸都要一致且档案大小不要太大</span>喔，否则替换图包会有问题
                <br />
                您可于图片右键点选『内容』了解该图片的名称、格式、尺寸
            </p>
            <p>
                <img src={"../../images/faq/06-01-04.jpg"} alt="" />
            </p>
            <p className="title">【步骤二】后台上传（要先完成活动创建才可以进行图包上传喔）</p>
            <p>您于后台先上传完毕后，会出现『上传成功』的字样</p>
            <p>
                <img src={"../../images/faq/06-01-05.jpg"} alt="" className="full" />
            </p>
            <p>
                此时可点选『预览』，查看活动页图包更新状况
                <br />
                <span>（预览不会连动您的活动时间、门槛或奖金等设定显示，只提供图包变化检视）</span>
            </p>
            <p>
                <img src={"../../images/faq/06-01-06.jpg"} alt="" />
                <br />
                <img src={"../../images/faq/06-01-07.jpg"} alt="" />
            </p>
            <p>
                <span>确认无误后按下『发布』，再点击模块右上角的『储存』</span> ；即可完成活动页图包更换
            </p>
            <p>
                <img src={"../../images/faq/06-01-08.jpg"} alt="" className="full" />
            </p>
        </div>
    );
};
// 6-2
export const ChangeTemplateDuringEvent = () => {
    return (
        <div className="faq-detail-info">
            <p>无法更换。</p>
        </div>
    );
};
// 6-3
export const DownloadEventPackageMaterials = () => {
    return (
        <div className="faq-detail-info">
            <p>
                ●下载活动图包
                <br />
                <span>活动模版＞选择模版＞图包说明</span>
                <img src={"../../images/faq/06-03-01.jpg"} alt="" />
            </p>
            <p>
                ●下载广宣素材
                <br />
                <span>活动模版＞选择模版＞活动设定文件/素材下载</span>
                <img src={"../../images/faq/06-03-02.jpg"} alt="" />
            </p>
        </div>
    );
};
// 6-4
export const UploadPackageWithoutChangingImages = () => {
    return (
        <div className="faq-detail-info">
            <p>无需上传图包，系统默认图包 。</p>
        </div>
    );
};
// 6-5
export const NoImagesAfterUploadingPackage = () => {
    return (
        <div className="faq-detail-info">
            <p>
                请您先刷新页面，如无法显示请再上传一次后再点选发布。
                <br />
                <span className="small">
                    <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                    请确认您的<span>图片档名/尺寸/档案格式</span>是否有符合模版规范
                </span>
            </p>
            <p>
                <img src={"../../images/faq/06-05-01.png"} alt="" className="full" />
            </p>
            <p>如持续无法成功，请联系客服专员。</p>
        </div>
    );
};
// 7 ---------------------------------------------------------------------------------
// 7-1
export const CanUploadBonusTable = () => {
    return (
        <div className="faq-detail-info">
            <p>无法，营运红包尚未支援奖金模型上传功能。未用完点数将作废。</p>
        </div>
    );
};
// 7-2
export const EventPrizeDecimalSetting = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以的，奖金金额支持「<span>小数点后2位</span>」。
            </p>
        </div>
    );
};
// 7-3
export const LimitPrizeClaims = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>可以的</span>，申请次数：管端路径「<span>营运红包＞活动条件＞申请次数</span>」
                <br />
                可限定单一会员于「活动期间」内可申请的次数或「每日」申请的次数，留空＝不限制 。
            </p>
            <p>
                <img src={"../../images/faq/07-03-01.jpg"} alt="" className="full" />
            </p>
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                如会员<span>已达申请次数上限</span>再度领奖，
                <br />
                活动页将显示『感谢参与，您申请已达活动限制次数！』。
            </p>
            <p>
                <img src={"../../images/faq/07-03-02.jpg"} alt="" />
            </p>
        </div>
    );
};
// 7-4
export const WhatIsWinList = () => {
    return (
        <div className="faq-detail-info">
            <p>中奖名单：会员端的中奖名单轮播 ( 如图 )。</p>
            <p>
                <img src={"../../images/faq/07-04-01.jpg"} alt="" />
            </p>
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                功能解说
                <img src={"../../images/faq/07-04-02.jpg"} alt="" />
                不显示：系统将不会显示前台活动页
                <br />
                实际名单：实际会员领取名单 ( 若您有设置多个群组仅会显示该会员所属于群组的实际名单 )
                <br />
                <span className="small">举例：会员为普通会员，该会员只会看见其他普通会员的领奖状况</span>
                <br />
                虚拟名单+实际名单：系统将会共用名单 ( 虚拟+实际 )
                <br />
                <span className="small">举例：会员为VIP会员，该会员会看见虚拟VIP会员与真实VIP会员的领奖状况</span>
            </p>
            <p>
                ★如何上传虚拟名单：<span>请您先创立活动完成，再上传虚拟名单</span>。
                <br />
                管端路径「<span>营运红包＞活动条件＞中奖名单＞虚拟名单+实际名单＞范例下载＞上传虚拟名单</span>」
            </p>

            <p className="step">
                <span>STEP1. 中奖名单＞虚拟名单+实际名单</span>
            </p>
            <p>
                <img src={"../../images/faq/07-04-03.jpg"} alt="" />
            </p>
            <p className="step">
                <span>STEP2. 范例下载＞填写虚拟名单</span>
            </p>
            <p>
                <img src={"../../images/faq/07-04-04.jpg"} alt="" />
            </p>
            <p>
                请注意！文档 demo_fuke 的派发群组对照为管端设定条件的派发群组， <br />
                假奖金金额请符合设定条件。
            </p>
            <p>
                <img src={"../../images/faq/07-04-05.jpg"} alt="" />
            </p>
            <p className="step">
                <span>STEP3. 上传虚拟名单</span>
            </p>
            <p>
                <img src={"../../images/faq/07-04-06.jpg"} alt="" />
            </p>
            <p>
                ( 1 ) 档案编辑完成，请存成.csv档
                <br />( 2 ) 上传名单限制1万笔(档案需小于1MB)
            </p>
        </div>
    );
};
// 7-5
export const UnableUploadVirtualActual = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                若出现『档案格式须为UTF-8』，无法上传成功
                <img src={"../../images/faq/07-05-01.jpg"} alt="若出现『档案格式须为UTF-8』，无法上传成功" />
            </p>
            <p className="step">
                <span>STEP1. demo_fuke.csv 右键选『开启新档』</span>
            </p>
            <p>
                <img src={"../../images/faq/07-05-02.png"} alt="STEP1. demo_fuke.csv 右键选『开启新档』" />
            </p>
            <p className="step">
                <span>STEP2. 开启新档后＞另存为…＞编码：UTF-8＞存档</span>
            </p>
            <p>
                <img
                    src={"../../images/faq/07-05-03.png"}
                    alt="STEP2. 开启新档后＞另存为…＞编码：UTF-8＞存档"
                    className="full"
                />
            </p>
            <p className="step">
                <span>STEP3. 上传时请确认您的名单后上传＞成功</span>
            </p>
            <p>
                <img
                    src={"../../images/faq/07-05-04.jpg"}
                    alt="STEP3. 上传时请确认您的名单后上传＞成功"
                    className="full"
                />
            </p>
            <p className="step">
                <span>STEP4. 上传后＞虚拟名单</span>
                <br />
                您可以查询您上传的名单。
                <img src={"../../images/faq/07-05-05.jpg"} alt="STEP4. 上传后＞虚拟名单" className="full" />
            </p>
        </div>
    );
};
// 7-6
export const SetBudgetTips = () => {
    return (
        <div className="faq-detail-info">
            <p>
                设置预算：管端路径「<span>营运红包＞活动条件＞派发群组＞总预算/每日预算</span>」
            </p>
            <p>
                <img src={"../../images/faq/07-06-01.jpg"} alt="" className="full" />
            </p>
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                预算注意事项
            </p>
            <p>
                设定【派发已达上限：视同未中奖】
                <br />
                <span>会员仍可进行抽奖，并扣除活动点数及申请次数，派奬金額將為0元(未中獎)。</span>
            </p>
            <p>
                设定【派发已达上限：无法抽红包】
                <br />
                会员无法进行抽奖，活动页将显示『今日奖项已发完，请明天继续参加，感谢您的支持』。
            </p>
        </div>
    );
};
// 7-7
export const SetWinningZero = () => {
    return (
        <div className="faq-detail-info">
            <p>可以的，若真奖金设定于 0，则假奖金最小金额也必须设定 0</p>
            <p>
                <FontAwesomeIcon icon="fa-regular fa-lightbulb" className="icon" />
                当会员抽中时
                <br />
                会员端：开奖画面显示未中奖，领奖记录显示未中奖，往来记录显示「營運紅包 交易额度0.00」
                <br />管 端：该笔派奖显示于派奖记录详情中，可领金额显示「0.00」
            </p>
        </div>
    );
};
// 7-8
export const DisplayHighestPrize = () => {
    return (
        <div className="faq-detail-info">
            <p>可以的，假奖金目前支持两种设定</p>
            <p>
                <span className="num">1</span> 固定：<span>活动说明页只显示最高奖金数值</span>
            </p>
            <p>
                <span className="num">2</span> 选择区间值：<span>只要最小假奖金设定0，活动说明页即可显示最高奖金</span>
            </p>
            <p>
                设定：管端路径「<span>优惠中心＞抽红包优惠＞营运红包＞活动条件＞假奖金</span>」
            </p>
        </div>
    );
};
// 8 ---------------------------------------------------------------------------------
// 8-1
export const CheckEventDistribution = () => {
    return (
        <div className="faq-detail-info">
            <p>
                管端路径「 <span>优惠中心＞抽红包优惠＞营运红包＞选择查询的活动＞报表资讯/成效分析</span> 」
            </p>
            <p>
                <img src={"../../images/faq/08-01-01.jpg"} alt="" className="full" />
            </p>
            <p>报表资讯：派发状态 / 会员账号 / 可领金额 / 未中奖名单</p>
            <p>
                <img src={"../../images/faq/08-01-02.png"} alt="" className="full" />◆
                未中奖名单：会员因活动预算已达上限未能领奖之纪录处
            </p>
            <p>成效分析：派彩統計與參與率統計</p>
            <p>
                <img src={"../../images/faq/08-01-03.jpg"} alt="" className="full" />
            </p>
        </div>
    );
};
// 8-2
export const CheckTotalPrizeClaimed = () => {
    return (
        <div className="faq-detail-info">
            <p>
                报表请于管端路径「<span>营运红包＞派发纪录＞派发金额</span>」查看。
            </p>
            <p>
                <img src={"../../images/faq/08-02-01.jpg"} alt="" className="full" />
            </p>
            <p>
                点选派发金额后，此处记录 <span>总派发金额、领奖会员账号</span>与<span>派发金额</span>
            </p>
            <p>
                <img src={"../../images/faq/08-02-02.jpg"} alt="" className="full" />
            </p>
        </div>
    );
};
// 9 ---------------------------------------------------------------------------------
// 9-1
export const MembersViewNewEvent = () => {
    return (
        <div className="faq-detail-info">
            <p>『营运红包』活动开始后，会员登入后可从以下位置获得活动信息</p>
            <p></p>
            <p>
                ●无需设定
                <br />① 网页/手机版右下<span>红包icon浮层版位</span>
            </p>
            <p>
                <img src={"../../images/faq/09-01-01.png"} alt="" />
            </p>
            <p>
                ② 优惠中心＞ <span>我的优惠＞任务</span>
            </p>
            <p>
                ●需设定图文
                <br />③ 优惠＞活动
            </p>
        </div>
    );
};
// 10 ---------------------------------------------------------------------------------
// 10-1
export const DifferencesBetweenEvent = () => {
    return (
        <div className="faq-detail-info">
            <p>「营运红包」为「营运活动」进行全面优化和大升级，致力于更好地满足业主办活动的需求及体验感受。</p>
            <p>
                BBIN
                提供全方位的活动设置选项，包括活动时间、累计条件、打码条件和层级条件…等，从而打造一个独一无二的活动页面，以提高您营运网的会员数、货量。
            </p>
            <p>
                优化项目：
                <br />
                活动时间设定： 您可以根据营运需求随时开始或停止活动，使其更加灵活！
                <br />
                累计条件设定： 提供存款、打码累计条件，让您可以根据不同的活动需求设置条件。
                <br />
                打码条件设定： 您能够根据活动的需求来制定出款打码倍数。
                <br />
                层级条件设定： 层级条件筛选，以帮助业主更精确地管理和控制活动参与者的层级设置，以提高管理效率。
            </p>
            <p>
                通过这些优化，提供更灵活、个性化的活动设置选项，以满足您的活动需求，从而提高营运网的货量并提升用户体验。
            </p>
        </div>
    );
};
// 10-2
export const SetOutgoingAudit = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>可以</span>，依活动需求设立出款打码倍数或无需稽核。
                <br />
                稽核设定：管端路径「<span>营运红包＞稽核设定＞固定倍率 / 无稽核</span>」
            </p>
        </div>
    );
};
// 10-3
export const CanIRestrict = () => {
    return (
        <div className="faq-detail-info">
            <p>
                <span>可以</span>， 请于 『开放领奖装置 』勾选行动网页版（仅限寰宇馆官方版）、行动网页版
                (H5)（仅限寰宇馆官方版）以及 APP版。
                <br />
                示意图：
                <br />
                <img src={"../../images/faq/10-05-01.jpg"} alt="" />
            </p>
        </div>
    );
};
// 10-4
export const EventAwardsReverseHandling = () => {
    return (
        <div className="faq-detail-info">
            <p>
                可以的，可以在派发纪录详情中进行该奖金的冲销
                <br />
                设定：管端路径「 <span>优惠中心＞抽红包优惠＞活动管理＞报表信息＞派发记录详情＞冲销</span> 」
                <br />
                冲销后，
                <br />
                管 端：活动设定被扣除包数及总预算/每日预算将会回补
                <br />
                会员端：会员的累计点数与抽奖次数皆不归还，会员的往来记录会有扣除奖项记录
            </p>
        </div>
    );
};
