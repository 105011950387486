// scss
import "./index.scss";

const FeedbackSucceed = ({setOverlay, setSendSucceed}) => {
    return (
        <div className="feedback-success">
            <div
                className="feedback-close"
                onClick={() => {
                    setOverlay(false);
                    setSendSucceed(true);
                }}></div>
            <div className="feedback-intro">
                <h3>已收到您的反馈，谢谢！</h3>
                <p>
                    营运红包专员将于
                    <br />
                    周一至周五 <span>09:30~17:30</span>
                    <br />
                    上班时间与您联系
                </p>
            </div>
        </div>
    );
};
export default FeedbackSucceed;
