import {Link, NavLink, useLocation} from "react-router-dom";
import {useEffect, useState, useContext} from "react";
import {StoreContext} from "../pages/StoreContext";

const PageHeader = () => {
    const {useGtmSender} = useContext(StoreContext);
    const {pathname} = useLocation();

    // gtm
    const gtmSender = useGtmSender();
    const handleGtmClick = (obj) => {
        gtmSender(obj);
    };
    const handleGtmBBlogoClick = (obj) => {
        // bbin logo
        if (pathname !== "/") {
            gtmSender(obj);
        }
    };

    // 滾動時header背景判斷
    const [isScrolled, setIsScrolled] = useState(false); //判斷要不要加 class name
    const [scrollHeight, setScrollHeight] = useState(0); //抓高度

    useEffect(() => {
        function onScroll() {
            const currentPositionHeight = window.scrollY;

            if (currentPositionHeight > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
            setScrollHeight(currentPositionHeight <= 0 ? 0 : currentPositionHeight);
        }

        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [scrollHeight]);

    // 錨點定位
    const handleScroll = (elementId) => {
        setTimeout(() => {
            const navTemplateElement = document.getElementById(elementId);
            const offset = -105;
            if (navTemplateElement) {
                window.scrollTo({
                    top: navTemplateElement.offsetTop + offset,
                    behavior: "smooth",
                });
            }
        }, 100);
    };

    useEffect(() => {
        // 在頁面載入時檢查 URL 中的錨點
        const hash = window.location.hash;
        if (hash) {
            window.scrollTo(0, 0);
        }
    }, []);

    return (
        <header className={isScrolled ? "scrolled" : ""}>
            <div className="wrap">
                <nav>
                    {/* bblogo */}
                    <Link
                        to="/"
                        onClick={() => {
                            handleGtmBBlogoClick({
                                event: "首頁按鈕",
                            });
                        }}></Link>
                    <ul>
                        <li>
                            <Link
                                to="/"
                                onClick={() => {
                                    handleGtmClick({
                                        event: "首頁_首頁置頂列",
                                        breadcrumbs: "首頁",
                                        memo: "推薦模版",
                                    });
                                    handleScroll("navTemplate");
                                }}>
                                推荐模版
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/"
                                onClick={() => {
                                    handleGtmClick({
                                        event: "首頁_首頁置頂列",
                                        breadcrumbs: "首頁",
                                        memo: "服務優點",
                                    });
                                    handleScroll("navService");
                                }}>
                                服务优点
                            </Link>
                        </li>
                        <li>
                            <NavLink
                                to="/template"
                                onClick={() => {
                                    handleGtmClick({
                                        event: "首頁_首頁置頂列",
                                        breadcrumbs: "首頁",
                                        memo: "更多模版",
                                    });
                                }}>
                                更多模版
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/faq"
                                onClick={() => {
                                    handleGtmClick({
                                        event: "首頁_首頁置頂列",
                                        breadcrumbs: "首頁",
                                        memo: "常见问题",
                                    });
                                }}>
                                常见问题
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};
export default PageHeader;
