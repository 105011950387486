// scss
import {useState} from "react";
import "./index.scss";

import FeedbackSucceed from "./FeedbackSucceed";
import FeedbackForm from "./FeedbackForm";

const Feedback = () => {
    // 彈窗
    const [overlay, setOverlay] = useState(false);
    // 表單成功
    const [sendSucceed, setSendSucceed] = useState(true); //true在表單頁，false在成功頁

    return (
        <div className="feedback-wrap">
            <div
                className="feedback-tag"
                onClick={() => {
                    // 打開彈窗
                    setOverlay(true);
                }}>
                <p>问题回馈</p>
            </div>
            {overlay && (
                <>
                    {sendSucceed ? (
                        // 表單頁
                        <FeedbackForm setOverlay={setOverlay} setSendSucceed={setSendSucceed} />
                    ) : (
                        // 成功頁
                        <FeedbackSucceed setOverlay={setOverlay} setSendSucceed={setSendSucceed} />
                    )}

                    <div
                        className="overlay"
                        onClick={() => {
                            setOverlay(false); // 關閉彈窗
                            setSendSucceed(true); //確保是在表單頁
                        }}></div>
                </>
            )}
        </div>
    );
};
export default Feedback;
